import { bool, string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import { StudentInfoRules } from "./types";

export function rules(): StudentInfoRules {
  return {
    email2: string().nullable(),
    cellphone: phoneNumber().nullable().required("required"),
    smsPermission: bool().nullable().required("required"),
  };
}
