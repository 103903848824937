<template>
  <form @submit.prevent="submit">
    <h1 class="h2">Room Reservations</h1>
    <hr />
    <div v-html="description"></div>
    <base-radio
      v-model="form.stayInRoom"
      name="stayInRoom"
      :label="`Would you like to reserve your current room ${room} for ${termName}?`"
      :options="[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]"
      option-value="value"
      option-label="label"
      :rules="rules.stayInRoom"
      :form-name="formName"
    />

    <base-radio
      v-if="form.stayInRoom"
      v-model="form.keepRoommates"
      name="keepRoommates"
      label="Do you wish to change roommates?"
      :options="[
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ]"
      option-value="value"
      option-label="label"
      :rules="rules.keepRoommates"
      :form-name="formName"
    />
    <div v-if="form.keepRoommates">
      <p>
        If your current roommate has decided not to reserve your room, you may
        invite a new roommate on the next page.
      </p>
      <p>
        <strong>
          NOTE: If your current roommate decides to reserve your room as well,
          your new roommate invitation will be canceled and you will be assigned
          to your reserved room with your current roommate.
        </strong>
      </p>
      <p>
        The resident wishing to make a roommate change must find a new room.
      </p>
    </div>

    <BaseTextArea
      v-if="form.keepRoommates !== null"
      v-model="form.comments"
      name="comments"
      label="Comments"
      style="max-width: 65ch"
    />

    <BaseSubmitButton class="btn btn-lg btn-primary mt-3" />
  </form>
</template>

<script setup lang="ts">
import axios from "axios";
import { reactive, watch } from "vue";
import { boolean } from "yup";

import { reservationRequests } from "@/api";
import { useForm } from "@/utilities/validations";

interface Props {
  room: string;
  description: string;
  termId: number;
  termName: string;
  housingPoolId: number;
}

const props = defineProps<Props>();

const form = reactive({
  termId: props.termId,
  housingPoolId: props.housingPoolId,
  stayInRoom: null as null | boolean,
  keepRoommates: null as null | boolean,
  comments: "" as string,
});

watch(
  () => form.stayInRoom,
  () => {
    form.keepRoommates = null;
    form.comments = "";
  }
);

watch(
  () => form.keepRoommates,
  () => {
    form.comments = "";
  }
);

const formName = "reservationRequestForm";
const { submit: submitFn } = useForm(formName);
const rules = {
  stayInRoom: boolean().nullable().required("required"),
  keepRoommates: boolean().nullable().required("required"),
};

const submit = submitFn(async () => {
  try {
    const res = await reservationRequests.create({ data: form });
    window.location.assign(res.location);
  } catch (e) {
    if (axios.isAxiosError(e) && e.response) {
      window.location = e.response.data.location;
    }
  }
});
</script>
