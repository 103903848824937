import Rails from "@rails/ujs";
try {
  Rails.start();
} catch (e) {}
import "popper.js";
import "bootstrap";
import "../javascripts/vue";

import { Modal } from "bootstrap";
import { Config } from '@js-from-routes/client'

Config.baseUrl = import.meta.env.VITE_RUBY_BASE


const _controllers = import.meta.globEager(
  "./../javascripts/controllers/**/*.ts"
);

document.addEventListener("DOMContentLoaded", () => {
  const checkInWizardModal = document.getElementById("checkInModal");
  if (checkInWizardModal) {
    new Modal(checkInWizardModal, {
      backdrop: "static",
    }).toggle();
  }

  Array.from(document.querySelectorAll("nav li a"))
    .filter((element) => (element as HTMLAnchorElement).href === location.href)
    .forEach((x) => {
      x.classList.add("active");
      let y = x.parentElement;
      while (y) {
        if (
          y.previousElementSibling?.classList?.contains(
            "sidebar__item"
          )
        ) {
          y.previousElementSibling?.classList?.add("active");
        }

        y = y.parentElement;
      }
    });
});

Rails.confirm = (message, element) => {
  const dialog = document.querySelector("#confirmation-modal");
  const dialogModal = new Modal(dialog);
  const content = dialog.querySelector("#modal-content");
  const ok = dialog.querySelector("#ok-button");
  content.innerHTML = message;
  ok.addEventListener("click", (event) => {
    event.preventDefault();
    dialogModal.hide();
    const old = Rails.confirm;
    Rails.confirm = () => true;
    (element as HTMLElement).click();
    Rails.confirm = old;
  });
  dialogModal.show();
  return false;
};
