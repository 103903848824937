<template>
  <h1>Medical Notes</h1>
  <hr />
  <form @submit.prevent="submit">
    <div class="mb-3">
      <BaseCheckBox
        v-model="checks.allergies"
        label="No Allergies"
        name="allergiesCheck"
      />
      <BaseTextArea
        v-model="form.allergies"
        :disabled="checks.allergies"
        name="allergies"
        label="Allegies"
        div-class="mb-2"
        :rules="rules.allergies"
        :form-name="formName"
      />
    </div>
    <div class="mb-3">
      <BaseCheckBox
        v-model="checks.medicalConditions"
        label="No Medical Conditions"
        name="medicalConditionsCheck"
      />
      <BaseTextArea
        v-model="form.medicalConditions"
        :disabled="checks.medicalConditions"
        name="medicalConditions"
        label="Medical Conditions"
        div-class="mb-2"
        :rules="rules.medicalConditions"
        :form-name="formName"
      />
    </div>
    <div class="mb-3">
      <BaseCheckBox
        v-model="checks.medications"
        label="No Medications"
        name="medicationsCheck"
      />
      <BaseTextArea
        v-model="form.medications"
        :disabled="checks.medications"
        name="medications"
        label="Medications"
        div-class="mb-2"
        :rules="rules.medications"
        :form-name="formName"
      />
    </div>
    <BaseSubmitButton style="width: fit-content" class="btn btn-primary" />
  </form>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";

import { medical } from "@/api";
import { useForm } from "@/utilities/validations";

type Form = {
  id?: number;
  allergies: string | null;
  medications: string | null;
  medicalConditions: string | null;
};

type Props = {
  value: Form;
};

const props = defineProps<Props>();

const form = reactive(props.value);

const checks = reactive({
  allergies: !props.value.allergies,
  medications: !props.value.medications,
  medicalConditions: !props.value.medicalConditions,
});

const rules = computed(() => ({
  allergies: [(v: string | null) => checks.allergies || !!v || "required"],
  medicalConditions: [
    (v: string | null) => checks.medicalConditions || !!v || "required",
  ],
  medications: [(v: string | null) => checks.medications || !!v || "required"],
}));

const { formName, submit: submitFn, validateField } = useForm("medicalForm");

const submit = submitFn(async () => {
  if (form.id) {
    const { location } = await medical.update({
      params: { id: form.id },
      data: { medical: form },
    });
    window.location.assign(location);
  } else {
    const { location } = await medical.create({ data: { medical: form } });
    window.location.assign(location);
  }
});

watch(
  () => checks.allergies,
  (v: boolean) => {
    if (v) {
      form.allergies = null;
      validateField("allergies");
    }
  }
);

watch(
  () => checks.medicalConditions,
  (v: boolean) => {
    if (v) {
      form.medicalConditions = null;
      validateField("medicalConditions");
    }
  }
);

watch(
  () => checks.medications,
  (v: boolean) => {
    if (v) {
      form.medications = null;
      validateField("medications");
    }
  }
);
</script>
