<template>
  <Toast />
  <h2>Room Inspection</h2>
  <hr />
  <form @submit.prevent="submit">
    <InspectionStep
      v-model="form"
      :form-name="formName"
      :student-name="studentName"
      :room-name="roomName"
    />
    <base-submit-button :loading="loading" class="btn btn-primary my-3" />
  </form>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";

import { inspections } from "@/api";
import { useForm } from "@/utilities/validations";
import InspectionStep from "@/views/MoveInWizard/Index/components/InspectionStep/Index.vue";
import type { InspectionDefault } from "@/views/MoveInWizard/Index/components/InspectionStep/types";

interface Props {
  studentName: string;
  roomName: string;
  details: InspectionDefault[];
}

const props = defineProps<Props>();

const toast = useToast();

const loading = ref(false);
const form = ref({
  details: props.details.map((x) => {
    x.inState = null;
    x.itemNotes = null;
    return x;
  }),
  signature: "",
});

const formName = "inspections-form";
const { submit: handleSubmit } = useForm(formName);

const submit = handleSubmit(
  async () => {
    try {
      loading.value = true;
      const data = await inspections.create({
        data: {
          inspection: {
            studentSignatureConfirmation: form.value.signature,
            inspectionDetailsAttributes: form.value.details,
          },
        },
      });

      window.location.assign(data.location);
    } finally {
      loading.value = false;
    }
  },
  () => {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "There is an error in your form",
      life: 3000,
    });
  }
);
</script>
