<template>
  <card-to-mobile-layout title="For this holiday break I will be...">
    <div class="row">
      <div class="col-12">
        <base-radio
          :model-value="modelValue"
          label="For this holiday break I will be..."
          :field-options="{
            hideLabel: true,
          }"
          name="holidayPlans"
          :options="options"
          :form-name="formName"
          :rules="rules.holidayPlans"
          @update:model-value="$emit('update:modelValue', $event)"
        />
      </div>
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { Rules } from "../useValidations";

interface Props {
  modelValue: null | "E" | "S" | "H";
  formName: string;
  rules: Rules;
  leaveOptions: {
    stay: boolean;
    home: boolean;
    other: boolean;
  };
}

const props = defineProps<Props>();

const options = [] as { label: string; value: string }[];
if (props.leaveOptions.stay) {
  options.push({ label: "Staying in the dorm", value: "S" });
}
if (props.leaveOptions.home) {
  options.push({ label: "Going home", value: "H" });
}
if (props.leaveOptions.other) {
  options.push({
    label: "Going somewhere else (enter address below)",
    value: "E",
  });
}
</script>
