<template>
  <Toast />
  <h1 class="border-bottom pb-3 mb-3">Room Selection</h1>
  <p v-html="_headerText"></p>
  <ul>
    <li v-for="roommate in roommates" :key="roommate">{{ roommate }}</li>
  </ul>

  <div class="d-flex flex-column mb-3">
    <form @submit.prevent="submit">
      <template v-if="form.selectedRoom">
        <base-check-box
          v-model="form.confirmed"
          :form-name="formName"
          :rules="rules.confirmed"
          name="confirmation"
          :label="confirmText"
          div-class="mb-3"
        />
        <base-submit-button
          class="btn btn-primary text-nowrap mb-2"
          :loading="loading"
          text="Finalize Room Request"
        />
      </template>
      <room-selector
        v-model="form.selectedRoom"
        :buildings="buildingsForSelection"
      />
    </form>
  </div>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import { computed, reactive, ref } from "vue";

import { Validators } from "@/utilities/validations";

import RoomSelector from "./components/RoomSelector";
import { Building } from "./types";
import { useSubmission } from "./useSubmission";

interface Props {
  buildings?: Building[];
  roommates?: string[];
  confirmationText: string | null;
  headerText: string | null;
}

const props = withDefaults(defineProps<Props>(), {
  buildings: () => [],
  roommates: () => [],
});

const form = reactive({
  confirmed: false,
  selectedRoom: null as number | null,
});

const buildingsForSelection = ref(props.buildings);

const rules = {
  confirmed: [(v: boolean) => v || "required"] as Validators<unknown>,
};

const { submit, loading, formName } = useSubmission(
  form,
  buildingsForSelection
);

const confirmText = computed(
  () =>
    props.confirmationText ||
    "I understand that room requests are not guaranteed and are subject to change. Further, I understand that after I finalize my room request, I will not be able to change my request without contacting my residence hall dean."
);

const _headerText = computed(
  () =>
    props.headerText ||
    "To view a floor diagram select a floor number from your prefered building on the left. Then select a room from the diagram. You may zoom the diagram using the scroll wheel or buttons on the right. Once you finalize, a room request will be created for you and each member of your roommate group:"
);
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
