import { Ref, watch } from "vue";

import {
  type HolidayLeave,
  type Home,
  type Other,
  isHomeLeave,
  isOtherLeave,
} from "./types/holidayLeave";

export function useReset(
  form: Ref<HolidayLeave>,
  holidayLeaveId: number,
  resetForm: () => void,
) {
  watch(
    () => form.value.holidayPlans,
    async (val, prev) => {
      switch (val) {
        case "S":
          form.value = {
            holidayPlans: "S",
            holidayLeaveId,
            isReturning: form.value.isReturning,
          };
          break;
        case "H":
          form.value = {
            holidayPlans: "H",
            isReturning: form.value.isReturning,
            holidayLeaveId,
            ...(prev === "E" && isHomeLeave(form.value)
              ? {
                  leaveReturn: form.value.leaveReturn,
                  leaveDepart: form.value.leaveDepart,
                  isReturning: form.value.isReturning,
                }
              : {}),
          } as unknown as Home;
          break;
        case "E":
          form.value = {
            holidayPlans: "E",
            isReturning: form.value.isReturning,
            holidayLeaveId,
            ...(prev === "H" && isOtherLeave(form.value)
              ? {
                  leaveReturn: form.value.leaveReturn,
                  leaveDepart: form.value.leaveDepart,
                }
              : {}),
            destination: "",
            hostRelationship: "",
            hostAge: null,
            address: "",
            city: "",
            state: null,
            postal: "",
            transport: "",
            driver: "",
            carOwner: "",
            companions: "",
            hostphone: "",
            cellphone: "",
            ccParent: "",
            reason: "",
          } as unknown as Other;
          break;
      }

      resetForm();
    },
  );
}
