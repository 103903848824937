<template>
  <toast />
  <h1>Leave Details</h1>
  <div v-if="formOptions.headerText" v-html="formOptions.headerText"></div>
  <hr />
  <form class="leave-form" :disabled="isLoading" @submit.prevent="submit">
    <leave-form
      v-model="form"
      :form-options="formOptions"
      :form-name="formName"
    />
    <div v-if="formOptions.footerText" v-html="formOptions.footerText"></div>
    <div class="d-flex justify-content-md-center mb-4">
      <base-submit-button
        :loading="isLoading"
        class="btn btn-primary flex-grow-1 flex-md-grow-0"
      />
    </div>
  </form>

  <LeaveConfirmationDialog
    v-model:visible="confirmationDialog"
    :loading="isLoading"
    :text="formOptions.confirmText"
    :form="form"
    @submit="submit"
  />
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import { provide, reactive } from "vue";

import LeaveConfirmationDialog from "../components/LeaveConfirmationDialog.vue";
import LeaveForm from "../components/LeaveForm/Index.vue";
import {
  FormOptions,
  Leave,
  LeaveForm as LeaveFormType,
} from "../components/LeaveForm/types";
import { leaveFormKey } from "../components/LeaveForm/types";
import { useSubmission } from "./useSubmission";

export interface Props {
  formOptions: FormOptions;
  prevLeave?: Leave | null;
}
const props = defineProps<Props>();

const form = reactive({
  destination: "",
  hostRelationship: "",
  hostAge: null,
  address: "",
  city: "",
  state: null,
  postal: "",
  transport: "",
  ccParent: "",
  hostphone: "",
  cellphone: "",
  departDate: null,
  departTime: null,
  returnDate: null,
  returnTime: null,
  driver: "",
  companions: "",
  reason: "",
} as LeaveFormType);

provide(leaveFormKey, form);

const { confirmationDialog, formName, isLoading, submit } = useSubmission(
  form,
  props.formOptions.confirmText != null
);
</script>
