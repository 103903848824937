<template>
  <fieldset>
    <legend :class="{ optional: optional }">{{ title }}</legend>
    <base-textfield
      v-model="form.contactName"
      div-class="mb-3"
      label="Name"
      help-text="Include first and last name"
      :form-name="formName"
      :name="`${name}.contactName`"
      :rules="rules.contactName"
    />
    <base-dropdown
      ref="contactRelationDropdown"
      v-model="form.contactRelation"
      div-class="mb-3"
      label="Relationship"
      :name="`${name}.contactRelation`"
      :options="relations"
      option-label="label"
      option-value="value"
      editable
      :form-name="formName"
      :rules="rules.contactRelation as any"
      @click="openDropdown"
    />
    <base-address
      v-model:address1="form.address1"
      v-model:address2="form.address2"
      v-model:city="form.city"
      v-model:state="form.state"
      v-model:country="form.country"
      v-model:postal="form.postal"
      :name="name"
      :states="states"
      :countries="countries"
      :rules="rules"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="form.phone1"
      v-model:phoneType="form.phone1Type"
      title="Phone Number 1"
      :name="`${name}.phone1`"
      :rules="rules.phone1"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="form.phone2"
      v-model:phoneType="form.phone2Type"
      title="Phone Number 2"
      :name="`${name}.phone2`"
      :rules="rules.phone2"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="form.phone3"
      v-model:phoneType="form.phone3Type"
      title="Phone Number 3"
      :name="`${name}.phone3`"
      :rules="rules.phone3"
      :form-name="formName"
    />
  </fieldset>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import { FilterService } from "primevue/api";
import { computed, ref } from "vue";

import { CountryOptions, StateOptions } from "@/shared/BaseAddress.vue";

import { ContactValidator, EmergencyContact } from "../types";
import EmergencyContactPhone from "./EmergencyContactPhone.vue";

const contactRelationDropdown = ref();
const openDropdown = () => {
  contactRelationDropdown.value.openDropdown();
};

interface Props {
  title: string;
  formName: string;
  modelValue: EmergencyContact;
  name: string;
  rules: ContactValidator;
  states: StateOptions;
  countries: CountryOptions;
  optional?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  optional: false,
});

interface Emits {
  (e: "update:modelValue", payload: EmergencyContact): void;
}

const emit = defineEmits<Emits>();

const form = useVModel(props, "modelValue", emit, {
  passive: true,
  deep: true,
});

const relations = computed(() =>
  ["Uncle", "Aunt", "Parent", "Sibling", "Grand Parent"]
    .filter((v) =>
      //@ts-ignore
      FilterService.filters.contains(v, form.value.contactRelation)
    )
    .map((name) => ({ label: name, value: name }))
);
</script>

<style lang="scss" scoped></style>
