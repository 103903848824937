<template>
  <section class="card">
    <section class="card-body">
      <dl class="row mb-0">
        <template v-for="[title, value] in list">
          <template v-if="value">
            <dt class="col-sm-3">{{ title }}</dt>
            <dd class="col-sm-9">
              {{ value }}
            </dd>
          </template>
        </template>
        <template
          v-if="reservation.roommates && reservation.roommates.length > 0"
        >
          <dt class="col-sm-3">
            {{ "Roommate" + (reservation.roommates.length > 1 ? "s" : "") }}
          </dt>
          <dd class="col-sm-9">
            <ul v-if="reservation.roommates.length > 1">
              <li v-for="roommate in reservation.roommates">{{ roommate }}</li>
            </ul>
            <template v-else>
              {{ reservation.roommates[0] }}
            </template>
          </dd>
        </template>
      </dl>
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { Reservation } from "./types";

type Props = {
  reservation: Reservation;
};

const props = defineProps<Props>();

const list = computed(() => [
  ["Term", props.reservation.term],
  ["Building", props.reservation.building],
  ["Wing", props.reservation.wing],
  ["Room", props.reservation.room],
  ["Room Rate", props.reservation.roomRate],
]);
</script>
