import { bool, number, object, string } from "yup";

import { phoneNumber } from "@/utilities/validators";

const futurePlanOptionSchema = () =>
  object({
    name: string(),
    value: number(),
    feedback: string().nullable(),
    promptForDetails: bool(),
    planCategory: string().oneOf(["Resident", "Commuter", "Departing"]),
  });

export function useValidations() {
  const rules = {
    expectedCheckoutDt: string().nullable().required("required"),
    futurePlanOption: futurePlanOptionSchema().nullable().required("required"),

    forwardingAddress: {
      addressType: string()
        .oneOf(["Home", "Summer", "Other"],  "required")
        .nullable()
        .required("required"),
      address1: string().required("required"),
      address2: string().nullable(),
      city: string().required("required"),
      state: string().nullable().required("required"),
      postal: string().required("required"),
      country: string().required("required"),
      phone: phoneNumber().required("required"),
      email: string().required("required"),
    },

    summerPlans: bool().nullable().required("required"),
    summerStartDt: string().required("required"),
    summerEndDt: string().required("required"),
    summerReason: string()
      .oneOf(["Work", "School", "Both Work & School"])
      .nullable()
      .required("required"),

    stayingInRoom: bool().nullable().required("required"),
    changeHousing: bool().nullable().required("required"),
    housingChangeRequests: string().required("required"),

    planDetail: string().nullable().required("required"),
  };

  return {
    rules,
  };
}

export type Rules = ReturnType<typeof useValidations>["rules"]
