<template>
  <div
    ref="konvaDiv"
    class="konva-container"
    style="overflow: hidden; position: relative"
  ></div>
  <div
    class="d-flex flex-column align-items-end"
    style="position: absolute; top: 1rem; right: 1rem; z-index: 2"
  >
    <div role="toolbar" style="width: fit-content" class="btn-group mb-3">
      <button class="btn btn-outline-secondary" @click.prevent="zoomOut">
        <b-icon-dash />
      </button>
      <button class="btn btn-outline-secondary" @click.prevent="zoomIn">
        <b-icon-plus />
      </button>
    </div>
    <div class="map-legend card">
      <div class="card-body">
        <div class="card-title h5">Legend</div>
        <div class="card-text">
          <div class="d-flex align-items-center">
            <div
              class="map-color rounded border border-dark me-2"
              style="background: #b0e7c4"
            >
              &nbsp;
            </div>
            Available Room
          </div>
          <div class="d-flex align-items-center">
            <div
              class="map-color rounded border border-dark me-2"
              style="background: #e87b86"
            >
              &nbsp;
            </div>
            Unavailable
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BIconDash, BIconPlus } from "bootstrap-icons-vue";
import { computed, Ref, ref, toRefs } from "vue";

import { useCanvas } from "./canvas";
import { AbodeFloorDiagram } from "./types";

interface Props {
  floorPlan: AbodeFloorDiagram;
  modelValue?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
});

interface Emits {
  (e: "update:modelValue", payload: number | null): void;
}

const emit = defineEmits<Emits>();

const selected = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});
const konvaDiv = ref(null) as unknown as Ref<HTMLDivElement>;

const { floorPlan } = toRefs(props);

const { zoomIn, zoomOut } = useCanvas(floorPlan, selected, konvaDiv);
</script>

<style lang="scss" scoped>
.map-color {
  width: 1rem;
  height: 1rem;
}
</style>
