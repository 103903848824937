import { computed } from "vue";

import { ApplicationPriority, FieldSpec } from "./types";

export function useValidations(
  fieldSpecs: Record<string, FieldSpec>,
  hasPriorities: boolean
) {
  const rules = computed(() => {
    const innerRules = [];

    type Priorities = [
      ApplicationPriority | undefined,
      ApplicationPriority | undefined,
      ApplicationPriority | undefined
    ];

    if (hasPriorities) {
      innerRules.push([
        "priorities",
        [
          (val: Priorities) =>
            (!val.includes(null) && !val.includes(undefined)) || "required",
        ],
      ]);
    }

    return Object.fromEntries(innerRules);
  });

  return { rules };
}
