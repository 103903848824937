import { DateTime } from "luxon";

export interface LeaveForm {
  leaveId?: number;
  destination: string;
  hostRelationship: string;
  hostAge: string | null;
  address: string;
  city: string;
  postal: string;
  state: string | null;
  transport: string;
  departDate: DateTime | null;
  departTime: DateTime | null;
  returnDate: DateTime | null;
  returnTime: DateTime | null;
  ccParent: string;
  hostphone: string;
  cellphone: string;
  driver: string;
  companions: string;
  reason: string;
}

export type Leave = Omit<
  LeaveForm,
  "departTime" | "departDate" | "returnDate" | "returnTime"
> & {
  leaveDepart: string;
  leaveReturn: string;
};

export type FormType = "new" | "edit";

export interface FormOptions {
  simplified: boolean;
  headerText: string | null;
  footerText: string | null;
  confirmText: string | null;
  submittedText: string | null;
  states: { label: string; value: unknown }[];
  minDate: string;
  maxDate: string;
  homeAddress: {
    address: string;
    city: string;
    postal: string;
    state: string;
    hostphone: string;
    cellphone: string;
  };
}

export const leaveFormKey = Symbol();
