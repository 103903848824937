<template>
  <base-textfield
    name="student.email"
    disabled
    label="Email"
    :model-value="email"
    div-class="mb-3"
  />
  <base-textfield
    v-model="email2"
    label="Alternate Email"
    name="student.email2"
    :rules="rules.email2 as any"
    :form-name="formName"
    div-class="mb-3"
    optional
  />
  <base-textfield
    v-model="cellphone"
    name="student.cellphone"
    label="Cellphone"
    :rules="rules.cellphone as any"
    :form-name="formName"
    div-class="mb-3"
    help-text="Ex: +12222425, (404) 888-9999, etc"
  />
  <base-radio
    v-model="smsPermission"
    name="student.smsPermssion"
    label="We use text messages to keep you informed about residence life"
    :options="[
      { label: 'I consent to receive text messages', value: true },
      {
        label:
          'Please do not send automated text messages to this number from Abode.',
        value: false,
      },
    ]"
    option-value="value"
    option-label="label"
    :rules="rules.smsPermission as any"
    :form-name="formName"
  />
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";

import { StudentInfo, StudentInfoRules } from "./types";

interface Props {
  modelValue: StudentInfo;
  formName: string;
  email: string;
  rules?: StudentInfoRules;
}

const props = withDefaults(defineProps<Props>(), {
  rules: () => ({ email2: [], smsPermission: [], cellphone: [] }),
});

interface Emits {
  (e: "update:modelValue", payload: StudentInfo): void;
}

const emit = defineEmits<Emits>();

const { modelValue } = toRefs(props);

const { email2, cellphone, smsPermission } = computedModels(modelValue, emit);
</script>
