import { computed, markRaw, reactive, ref } from "vue";

import CompleteStep from "./components/CompleteStep.vue";
import ContactInfoStep from "./components/ContactInfoStep.vue";
import InspectionStep from "./components/InspectionStep/Index.vue";
import MoveInStep from "./components/MoveInStep.vue";
import { Step } from "./types";

export function useSteps(requireInspection: boolean) {
  const steps = reactive([
    {
      name: "Contact",
      infoText: "Update your contact information",
      component: markRaw(ContactInfoStep),
      completed: false,
    },
  ]) as Step[];

  if (requireInspection) {
    steps.push({
      name: "Inspection",
      infoText: "Inspection",
      component: markRaw(InspectionStep),
      completed: false,
    });
  }

  steps.push(
    {
      name: "MoveIn",
      infoText: "Complete move in process",
      component: markRaw(MoveInStep),
      completed: false,
    },
    {
      name: "Complete",
      infoText: "",
      component: markRaw(CompleteStep),
      completed: false,
    }
  );

  const currentIndex = ref(0);

  const currentStep = computed(() => steps[currentIndex.value]);

  const next = () => {
    currentIndex.value = Math.min(steps.length, currentIndex.value + 1);
  };

  return { steps, currentIndex, currentStep, next };
}
