<template>
  <v-dialog
    v-model:visible="vis"
    :show-header="false"
    modal
    class="mx-3"
    style="width: 60ch; max-width: 100%"
  >
    <div v-html="confirmationText"></div>
    <div class="mt-3 d-flex justify-content-end">
      <base-submit-button
        :loading="loading"
        class="btn btn-primary me-3"
        @click="emit('submit')"
      />
      <button class="btn btn-secondary" @click="emit('update:visible', false)">
        Cancel
      </button>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import { DateTime } from "luxon";
import VDialog from "primevue/dialog";
import { computed } from "vue";

import { LeaveForm } from "./LeaveForm/types";

interface Props {
  loading: boolean;
  visible: boolean;
  text: string | null;
  form: LeaveForm;
}

const props = defineProps<Props>();

interface Emits {
  (e: "submit"): void;
  (e: "update:visible", payload: boolean): void;
}

const emit = defineEmits<Emits>();

const vis = useVModel(props, "visible", emit);

const confirmationText = computed(() => {
  if (!props.text) {
    return "";
  }

  //NOTE: We use snake case for uniformity with other templates used in rails
  const replacements = {
    depart_date: props.form.departDate?.toLocaleString(DateTime.DATE_SHORT),
    depart_time: props.form.departTime?.toLocaleString(DateTime.TIME_SIMPLE),
    return_date: props.form.returnDate?.toLocaleString(DateTime.DATE_SHORT),
    return_time: props.form.returnTime?.toLocaleString(DateTime.TIME_SIMPLE),
    return_dow: props.form.returnDate?.weekdayLong,
    depart_dow: props.form.departDate?.weekdayLong,
  };

  return Object.entries(replacements).reduce(
    (txt, [key, rep]) => txt.replace(new RegExp(`%${key}%`, "g"), rep || ""),
    props.text
  );
});
</script>
