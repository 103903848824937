<template>
  <section aria-labelledby="forwarding-address" class="card">
    <header id="forwarding-address" class="card-header">
      Forwarding Address
    </header>
    <section class="card-body">
      <base-dropdown
        v-model="form.forwardingAddress.addressType"
        v-test="'forwardingAddress.addressType'"
        label="Address Type"
        name="forwardingAddress.addressType"
        :disabled="survey.type === 'End of Year'"
        :options="['Home', 'Summer', 'Other']"
        :form-name="formName"
        :rules="rules.forwardingAddress.addressType"
      />
      <base-textfield
        v-model="form.forwardingAddress.address1"
        label="Address 1"
        name="forwardingAddress.address1"
        :form-name="formName"
        :rules="rules.forwardingAddress.address1"
      />
      <base-textfield
        v-model="form.forwardingAddress.address2"
        optional
        label="Address 2"
        name="forwardingAddress.address2"
        :form-name="formName"
        :rules="rules.forwardingAddress.address2"
      />
      <base-textfield
        v-model="form.forwardingAddress.city"
        label="City"
        name="forwardingAddress.city"
        :form-name="formName"
        :rules="rules.forwardingAddress.city"
      />
      <base-dropdown
        v-if="form.forwardingAddress.country != null && !isInternational"
        v-model="form.forwardingAddress.state"
        v-test="'forwardingAddress.state'"
        label="State/Province"
        name="forwardingAddress.state"
        :options="statesForCountry"
        option-label="label"
        option-value="value"
        placeholder="Select State"
        :form-name="formName"
        :rules="rules.forwardingAddress.state"
      />
      <base-textfield
        v-if="form.forwardingAddress.country != null && !isInternational"
        v-model="form.forwardingAddress.postal"
        label="Postal/ZIP code"
        name="forwardingAddress.postal"
        :form-name="formName"
        :rules="rules.forwardingAddress.postal"
      />
      <base-dropdown
        v-model="form.forwardingAddress.country"
        label="Country"
        :name="`forwardingAddress.country`"
        :options="Object.values(countries)"
        option-label="label"
        option-value="value"
        placeholder="Select Country"
        :form-name="formName"
        :rules="rules.forwardingAddress.country"
      />
      <base-textfield
        v-model="form.forwardingAddress.phone"
        label="Phone"
        name="forwardingAddress.phone"
        :form-name="formName"
        :rules="rules.forwardingAddress.phone"
      />
      <base-textfield
        v-model="form.forwardingAddress.email"
        label="Email"
        name="forwardingAddress.email"
        :form-name="formName"
        :rules="rules.forwardingAddress.email"
      />
    </section>
  </section>
</template>

<script setup lang="ts">
import { computed, inject, Ref } from "vue";

import { CountryOptions, StateOptions } from "@/shared/BaseAddress.vue";

import { FuturePlans, FuturePlansSurvey } from "../types";
import { Rules } from "../useValidations";

interface Props {
  survey: FuturePlansSurvey;
  states: StateOptions;
  countries: CountryOptions;
  formName: string;
  rules: Rules;
}

const props = defineProps<Props>();

const form = inject("form") as Ref<
  FuturePlans.EndOfTerm | FuturePlans.EndOfYear
>;

const isInternational = computed(() => {
  if (form.value.forwardingAddress.country === null) {
    return false;
  }

  const countryRecord = props.countries[form.value.forwardingAddress.country];

  return countryRecord?.isoCode !== "USA" && countryRecord?.isoCode !== "CAN";
});

const statesForCountry = computed(() => {
  if (isInternational.value || form.value.forwardingAddress.country == null) {
    return [];
  }

  return Object.values(props.states).filter(
    (s) => s.countryCode === form.value.forwardingAddress.country
  );
});
</script>

<style lang="scss" scoped>
section.card-body > div:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
