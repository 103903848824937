import {
  computed,
  ComputedRef,
  inject,
  onBeforeUnmount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { BaseSchema } from "yup";

import { Validators } from "./types";

export interface Field {
  touched: Ref<boolean>;
  errorMessage: Ref<string>;
  validate: () => Promise<boolean>;
  showError: ComputedRef<boolean>;
}

function isYupSchema(value: unknown): value is BaseSchema {
  return (value as BaseSchema).isValid !== undefined;
}

interface FieldOptions {
  formName?: string;
}

export const useField = <T>(
  name: string,
  value: Ref<T>,
  rules?: Ref<Validators<T>>,
  options?: FieldOptions
): Field => {
  const form: Ref<Record<string, Field>> | undefined =
    options?.formName === undefined ? undefined : inject(options.formName);

  if (options?.formName !== undefined && !form) {
    throw new Error(`Could not find form: Form ${options.formName}`);
  }

  const errorMessage = ref("");

  const validate = async () => {
    if (rules === undefined) {
      return true;
    }

    if (Array.isArray(rules.value)) {
      if (rules.value.length === 0) {
        errorMessage.value = "";
        return true;
      }

      for (const rule of rules.value) {
        if (isYupSchema(rule)) {
          try {
            await rule.validate(value.value);
            errorMessage.value = "";
            continue;
          } catch (err) {
            errorMessage.value = (err as { errors: string[] }).errors[0];
            return false;
          }
        }

        const result = rule(value.value);
        if (result !== true) {
          errorMessage.value = result;
          return false;
        }
      }

      errorMessage.value = "";
      return true;
    }

    try {
      await rules.value.validate(value.value);
      errorMessage.value = "";
      return true;
    } catch (err) {
      errorMessage.value = (err as { errors: string[] }).errors[0];
      return false;
    }
  };

  const touched = ref(false);
  const showError = computed(() => touched.value && !!errorMessage.value);

  const state: Field = {
    touched,
    errorMessage,
    validate,
    showError,
  };

  if (form) {
    onMounted(() => {
      if (form.value[name] !== undefined) {
        throw new Error(
          `All fields names must be unique: Form ${options?.formName}, Field ${name}`
        );
      }

      form.value[name] = state;
    });

    onBeforeUnmount(() => {
      delete form.value[name];
    });
  }

  return state;
};
