import axios from "axios";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";

import { leaves } from "@/api";
import { stripPhoneNumber, toRailsDateTime } from "@/utilities";
import { useForm } from "@/utilities/validations";

import { Leave, LeaveForm } from "../components/LeaveForm/types";

export function useSubmission(form: LeaveForm, requireConfirmation: boolean) {
  const isLoading = ref(false);

  const formName = "leave-form";
  const { setFieldErrorMessage, submit: formSubmit } = useForm(formName);

  const toast = useToast();

  const showError = () =>
    toast.add({
      severity: "error",
      summary: "Errors in form",
      detail: "You seem to have a few errors in your form",
      life: 5000,
    });

  const confirmationDialog = ref(false);

  const handleError = (err: Error) => {
    if (axios.isAxiosError(err) && err.response) {
      Object.entries(err.response.data).forEach(([key, val]) => {
        if (key === "leave_return") {
          setFieldErrorMessage("returnDate", (val as string[]).join(", "));
          setFieldErrorMessage("returnTime", (val as string[]).join(", "));
          return;
        }

        if (key === "leave_depart") {
          setFieldErrorMessage("departDate", (val as string[]).join(", "));
          setFieldErrorMessage("departTime", (val as string[]).join(", "));
          return;
        }

        setFieldErrorMessage(key, (val as string[]).join(", "));
      });
      showError();
    }
  };

  const submit = formSubmit(async () => {
    if (requireConfirmation && !confirmationDialog.value) {
      confirmationDialog.value = true;
      return;
    }

    isLoading.value = true;

    const data = convertToLeave(form);

    try {
      const response = await leaves.create({ data });
      window.location.assign(response.location);
    }
    catch (err) {
      handleError(err as Error);
    }
    finally {
      isLoading.value = false;
    }
  }, showError);

  return { submit, confirmationDialog, isLoading, formName };
}

function convertToLeave(form: LeaveForm): Leave {
  let leaveDepart = null;
  let leaveReturn = null;
  if (
    form.departDate &&
    form.departTime &&
    form.returnDate &&
    form.returnTime
  ) {
    leaveDepart = toRailsDateTime(
      form.departDate.set({
        hour: form.departTime.hour,
        minute: form.departTime.minute,
        second: form.departTime.second,
      })
    );
    leaveReturn = toRailsDateTime(
      form.returnDate.set({
        hour: form.returnTime.hour,
        minute: form.returnTime.minute,
        second: form.returnTime.second,
      })
    );
  }

  return {
    ...form,
    leaveDepart: leaveDepart ?? "",
    leaveReturn: leaveReturn ?? "",
    hostphone: stripPhoneNumber(form.hostphone) ?? "",
    cellphone: stripPhoneNumber(form.cellphone) ?? "",
  };
}
