<template>
  <v-dialog
    v-model:visible="store.chooseRoommate.dialogs.invite"
    modal
    :show-header="false"
    position="top"
  >
    <form @submit.prevent="submit">
      <div class="form-inline mb-3">
        <label class="mb-2 me-2">Is your planned roommate a</label>
        <student-type-tabs v-model="form.inviteType" />
      </div>
      <find-student-field
        v-if="form.inviteType === 'Current'"
        v-model="form.email"
        :searching="searching"
        div-class="mb-2"
        :rules="rules.email"
        :form-name="formName"
        @search="searchSubmit"
      />
      <base-textfield
        v-else
        v-model="form.email"
        name="email"
        label="Email"
        div-class="mb-2"
        type="email"
        :rules="rules.email"
        :form-name="formName"
      />
      <base-textfield
        v-model="form.firstName"
        name="firstName"
        label="First Name"
        div-class="mb-2"
        :disabled="form.inviteType === 'Current'"
        :rules="rules.firstName"
        :form-name="formName"
      />
      <base-textfield
        v-model="form.lastName"
        name="lastName"
        label="Last Name"
        div-class="mb-2"
        :disabled="form.inviteType === 'Current'"
        :rules="rules.lastName"
        :form-name="formName"
      />
      <base-textfield
        v-if="form.inviteType !== 'Current'"
        v-model="form.phone"
        name="cellphone"
        label="Cell Phone"
        type="tel"
        :rules="rules.phone"
        :form-name="formName"
      />
    </form>

    <template #footer>
      <button
        :disabled="form.inviteType === 'Current' && !found"
        type="button"
        class="btn btn-primary"
        @click="submit"
      >
        Send Invite
      </button>
      <button type="button" class="btn btn-secondary" @click="close">
        Close
      </button>
    </template>
  </v-dialog>
</template>

<script lang="ts">
export default {
  name: "RoommateSearchModal",
};
</script>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { nextTick, Ref, ref, watch } from "vue";

import { useForm } from "@/utilities/validations";

import { useStore } from "../../../../store";
import { MemberInviteForm } from "../../../../types";
import FindStudentField from "./components/FindStudentField.vue";
import StudentTypeTabs from "./components/StudentTypeTabs.vue";
import { useStudentSearch } from "./useStudentSearch";
import { useValidations } from "./useValidations";

const store = useStore();

const newForm = (
  inviteType?: MemberInviteForm["inviteType"]
): MemberInviteForm => ({
  email: "",
  lastName: "",
  firstName: "",
  inviteType: inviteType ?? "Current",
});

const form: Ref<MemberInviteForm> = ref(newForm());

const {
  searchSubmit: searchSubmitFn,
  searched,
  found,
  searching,
} = useStudentSearch(form);

const { rules } = useValidations(form, searched, found);

const formName = "student-search-form";

const {
  submit: submitFn,
  validateField,
  reset: resetValidations,
} = useForm(formName);

const submit = submitFn(async () => {
  store.inviteNewMember(form.value);
});

const reset = async (
  inviteType: MemberInviteForm["inviteType"] = "Current"
) => {
  form.value = newForm(inviteType);
  await nextTick();
  resetValidations();
};

const close = () => {
  store.chooseRoommate.dialogs.invite = false;
};

watch(
  () => form.value.inviteType,
  (val) => reset(val)
);

watch(
  () => form.value.email,
  () => {
    if (form.value.inviteType === "Current") {
      form.value.firstName = "";
      form.value.lastName = "";
    }
  }
);

watch(
  () => store.chooseRoommate.dialogs.invite,
  () => reset()
);

const searchSubmit = searchSubmitFn(validateField);
</script>
