import { computed, Ref, UnwrapRef } from "vue";
import { object, string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import { HolidayLeave, isHomeLeave, isOtherLeave } from "./types/holidayLeave";

export function useValidations(form: Ref<HolidayLeave>) {
  const rules = computed(() => ({
    destination: string().required("required"),
    hostRelationship: string().required("required"),
    reason: string().required("required"),
    hostAge: string().nullable().required("required"),
    address: string().required("required"),
    city: string().required("required"),
    transport: string().nullable().required("required"),

    holidayPlans: string().nullable().required("required"),
    hostphone: phoneNumber().test(
      "hostphone",
      isOtherLeave(form) ? "Host or cell phone number is required" : "required",
      (value) => !isOtherLeave(form) || !!value || !!form.value.cellphone
    ),
    cellphone: phoneNumber().test(
      "cellphone",
      () => isOtherLeave(form) ? "Host or cell phone number is required" : "required",
      (value) => {
        if (isOtherLeave(form)) {
          return !!value || !!form.value.hostphone;
        }

        if (isHomeLeave(form)) {
          return !!value;
        }

        return true;
      }
    ),
    ccParent: string().email(),
    otherphone: phoneNumber(),
    leaveDepart: object()
      .nullable()
      .test("leaveDates", "required", (value) => !!value),
    leaveReturn: object()
      .nullable()
      .test("leaveDates", "required", (value) => !!value),
  }));

  return { rules };
}

export type Rules = UnwrapRef<ReturnType<typeof useValidations>>["rules"];
