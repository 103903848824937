import { useToast } from "primevue/usetoast";
import { Ref } from "vue";

import { maintenance } from "@/api";

import { MaintenanceRequest, MaintenanceRequestForm } from "./types";

export function useSubmission(
  hasRecentRequest: Ref<boolean>,
  requests: Ref<MaintenanceRequest[]>,
  dialogs: {
    recentRequest: boolean;
    newForm: boolean;
  }
) {
  const toast = useToast();

  const submit = async (values: MaintenanceRequestForm) => {
    try {
      const newReq = await maintenance.create({
        data: {
          maintenance: {
            roomMaintCatId: values.categoryId,
            issueSummary: values.summary,
            issueComments: values.comments,
            isSharedSpace: values.isSharedSpace,
            sharedSpaceName: values.sharedSpaceName,
          },
        },
      });

      hasRecentRequest.value = true;

      toast.add({
        summary: "Success",
        detail: "Your room maintenance form has been submitted",
        severity: "success",
        life: 5000,
      });

      requests.value = [newReq, ...requests.value];
    } catch (e) {
      toast.add({
        summary: "Error",
        detail: "Something has gone wrong, please contact your dean",
        severity: "error",
        life: 5000,
      });
    } finally {
      dialogs.newForm = false;
    }
  };

  return { submit };
}
