<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :optional="optional"
  >
    <div ref="inputGroup" class="input-group">
      <input
        :disabled="disabled"
        readonly
        :class="{ 'is-invalid': showError }"
        :value="model?.toFormat('MM/dd/yyyy') || ''"
        class="form-control"
        autocomplete="off"
        @click="open"
        @focus="open"
      />
      <button
        :disabled="disabled"
        type="button"
        class="btn btn-primary"
        @click="open"
      >
        <i class="bi-calendar"></i>
      </button>
    </div>
    <OverlayPanel ref="op" :show-close-icon="true" :dismissable="true">
      <Calendar
        v-model="jsDate"
        v-bind="$attrs"
        :min-date="innerMinDate"
        :max-date="innerMaxDate"
        :show-other-months="true"
        :select-other-months="true"
        year-navigator
        inline
      />
    </OverlayPanel>
    <Dialog v-model:visible="dialogOpen" modal :show-header="false">
      <div class="d-flex flex-column">
        <Calendar
          v-model="jsDate"
          :min-date="innerMinDate"
          :max-date="innerMaxDate"
          :show-other-months="true"
          :select-other-months="true"
          year-navigator
          inline
        />
        <button class="btn btn-primary mt-3" @click="dialogOpen = false">
          Close
        </button>
      </div>
    </Dialog>
    <HelpText
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    >
      <slot name="helpText" />
    </HelpText>
  </base-field>
</template>

<script lang="ts">
export default {
  name: "BaseDatePicker",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { DateTime } from "luxon";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import OverlayPanel from "primevue/overlaypanel";
import { computed, nextTick, Ref, ref, toRefs, watch } from "vue";

import { computedModel } from "@/utilities";
import { useBreakpoints } from "@/utilities/breakpoints";
import { useField, Validators } from "@/utilities/validations";

type ModelValue = DateTime | null;

interface Props {
  name: string;
  id?: string;
  modelValue: ModelValue;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
  disabled?: boolean;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<ModelValue>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  minDate: null,
  maxDate: null,
  rules: () => [],
  disabled: false,
});

const emit = defineEmits<{
  (e: "update:modelValue", value: ModelValue): void;
}>();

const innerId = computed(() => props.id || props.name);

const { rules, modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const { touched, validate, errorMessage, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

const jsDate = computed<Date | undefined>({
  get: () => model.value?.toJSDate(),
  set: (val) => (model.value = val ? DateTime.fromJSDate(val) : null),
});

watch(model, () => {
  nextTick(() => validate());
});

const innerMaxDate = computed(() => props.maxDate?.toJSDate());
const innerMinDate = computed(() => props.minDate?.toJSDate());

const { smaller } = useBreakpoints();
const isMobile = smaller("md");

const dialogOpen = ref(false);
const op = ref(null) as unknown as Ref<OverlayPanel>;
const inputGroup = ref(null) as unknown as Ref<HTMLDivElement>;

const open = (event: any) => {
  if (isMobile.value) {
    dialogOpen.value = true;
    return;
  }
  op.value.show(event, inputGroup.value);
};

watch(jsDate, () => {
  op.value.hide();
});
</script>

<style scoped lang="scss">
.p-calendar :deep(.p-datepicker-inline) {
  border: none;
}
</style>
