<template>
  <div
    v-bind="$attrs"
    :class="{
      'checks-list__row list-group-item': true,
      collapsed: collapsed,
    }"
    role="button"
    :aria-expanded="!collapsed"
    @click="toggleCollapse"
  >
    <div class="d-flex justify-content-between align-items-center w-100 pe-3">
      <div>
        {{ check.typeName }}
        <div style="font-size: 0.8rem" class="text-muted pt-1">
          {{ check.nightOf }} {{ check.checkInTime }}
        </div>
      </div>
      <div v-if="hasDetails" class="checks-list__row__icon">
        <i class="bi bi-chevron-left"></i>
      </div>
    </div>
  </div>
  <collapse-transition>
    <div v-if="!collapsed" class="border-bottom">
      <div class="py-3 px-3">
        <dl>
          <template v-for="[desc, value] in details">
            <template v-if="value">
              <dt class="col-md-3">{{ desc }}</dt>
              <dd class="col-md-9">{{ value }}</dd>
            </template>
          </template>
        </dl>
      </div>
    </div>
  </collapse-transition>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { Check } from "../types";

interface Props {
  showCharges: boolean;
  check: Check;
}

const props = defineProps<Props>();

const collapsed = ref(true);
let details = [
  ["Resolution Status", props.check.resolutionStatus],
  ["Excused", props.check.status],
  ["Excused Until", props.check.excusedUntil],
  ["Excused By", props.check.excusedBy],
  ["Excused Reason", props.check.excusedReason],
  ["Comments", props.check.comments],
  ["Room State", props.check.roomState],
] as Array<string[] | [string, null | string]>;

if (props.showCharges) {
  details = [
    ...details,
    ["Charge", props.check.charge == "$0" ? null : props.check.charge],
  ];
}

const hasDetails = details.some(([_, value]) => !!value);

const toggleCollapse = () => {
  if (hasDetails) {
    collapsed.value = !collapsed.value;
  }
};
</script>
