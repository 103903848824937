import { DateTime } from "luxon";
import { string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import type { LeaveForm } from "./types";

export function useValidations(form: LeaveForm, simplified: boolean) {
  let rules;
  if (simplified) {
    rules = {
      destination: string().required("required"),
      hostRelationship: string(),
      hostAge: string().nullable(),
      address: string(),
      city: string(),
      state: string().nullable(),
      transport: string(),
      ccParent: string().email("must be a valid email"),
      hostphone: phoneNumber(),
      cellphone: phoneNumber(),
      reason: string(),
      departDate: [(v: DateTime) => v !== null || "required"],
      departTime: [
        (v: DateTime) => v !== null || "required",
        (v: DateTime) => {
          if (form.departDate == null) {
            return true;
          }

          const adjustedTime = v.set({
            year: form.departDate.year,
            month: form.departDate.month,
            day: form.departDate.day,
          });

          return (
            DateTime.now().plus({ minutes: 5 }) <= adjustedTime ||
            "must be atleast 5 minutes after the current date and time"
          );
        },
      ],
      returnDate: [
        (v: DateTime) => !form.departDate || v !== null || "required",
      ],
      returnTime: [
        (v: DateTime) => !form.departDate || v !== null || "required",
      ],
    };
  } else {
    rules = {
      destination: string().required("required"),
      hostRelationship: string().required("required"),
      hostAge: string().nullable().required("required"),
      address: string().required("required"),
      city: string().required("required"),
      state: string().nullable().required("required"),
      transport: string().required("required"),
      ccParent: string().email("must be a valid email"),
      hostphone: phoneNumber().required("required"),
      cellphone: phoneNumber().required("required"),
      reason: string().required("required"),
      departDate: [(v: DateTime) => v !== null || "required"],
      departTime: [
        (v: DateTime) => v !== null || "required",
        (v: DateTime) => {
          if (form.departDate == null) {
            return true;
          }

          const adjustedTime = v.set({
            year: form.departDate.year,
            month: form.departDate.month,
            day: form.departDate.day,
          });

          return (
            DateTime.now().plus({ minutes: 5 }) <= adjustedTime ||
            "must be atleast 5 minutes after the current date and time"
          );
        },
      ],
      returnDate: [
        (v: DateTime) => !form.departDate || v !== null || "required",
      ],
      returnTime: [
        (v: DateTime) => !form.departDate || v !== null || "required",
      ],
    };
  }

  return { rules };
}
