import Konva from "konva";
import {
  onMounted,
  Ref,
  watch,
  WatchStopHandle,
  WritableComputedRef,
} from "vue";

import { AbodeFloorDiagram, AbodeFloorDiagramRoomItem } from "./types";

// Point relative to the page
interface PagePoint {
  kind: "Page";
  x: number;
  y: number;
}

// Point relative to the Stage
interface StagePoint {
  kind: "Stage";
  x: number;
  y: number;
}

// Point relative to the Canvas
interface CanvasPoint {
  kind: "Canvas";
  x: number;
  y: number;
}

type Point = PagePoint | CanvasPoint | StagePoint;

const baseColor = "#b0e7c4";
const hoverColor = "#bfbfbf";
const selectedColor = "#184f2c";
const lockedColor = "#e87b86";

function toLocalCoors(p: CanvasPoint, stage: Konva.Stage): StagePoint {
  return {
    kind: "Stage",
    x: (p.x - stage.x()) / stage.scaleX(),
    y: (p.y - stage.y()) / stage.scaleX(),
  };
}

function zoom(stage: Konva.Stage, direction: 1 | -1) {
  const scaleBy = 1.1;
  const oldScale = stage.scaleX();

  let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
  newScale = Math.max(1, newScale);

  const center: CanvasPoint = {
    kind: "Canvas",
    x: stage.width() / 2,
    y: stage.height() / 2,
  };

  const mousePointTo = toLocalCoors(center, stage);
  stage.scale({ x: newScale, y: newScale });

  const newPos = {
    x: center.x - mousePointTo.x * newScale,
    y: center.y - mousePointTo.y * newScale,
  };
  stage.position(newPos);

  stage.batchDraw();
}

function pinchZoom(stage: Konva.Stage) {
  Konva.hitOnDragEnabled = true;

  function getDistance(p1: Point, p2: Point) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1: Point, p2: Point) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  let center: CanvasPoint | null = null;
  let lastDist = 0;

  stage.on("touchmove", function (e) {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];

    if (!(touch1 && touch2)) {
      return;
    }

    stage.draggable(false);

    const p1: PagePoint = {
      kind: "Page",
      x: touch1.clientX,
      y: touch1.clientY,
    };

    const p2: PagePoint = {
      kind: "Page",
      x: touch2.clientX,
      y: touch2.clientY,
    };

    if (!center) {
      const centerCoors = getCenter(p1, p2);
      center = {
        kind: "Canvas",
        x: centerCoors.x - stage.container().getBoundingClientRect().x,
        y: centerCoors.y - stage.container().getBoundingClientRect().y,
      };
      return;
    }

    const dist = getDistance(p1, p2);

    if (!lastDist) {
      lastDist = dist;
    }

    const pointTo = toLocalCoors(center, stage);

    let scale = stage.scaleX() * (dist / lastDist);
    scale = Math.max(1, scale);

    stage.scale({ x: scale, y: scale });

    const newPos = {
      x: center.x - pointTo.x * scale,
      y: center.y - pointTo.y * scale,
    };

    stage.position(newPos);
    stage.batchDraw();

    lastDist = dist;
  });

  stage.on("touchend", function () {
    lastDist = 0;
    center = null;
    stage.draggable(true);
  });
}

function addScaling(stage: Konva.Stage) {
  const scaleBy = 1.1;
  stage.on("wheel", (e) => {
    e.evt.preventDefault();

    const pointer = {
      ...stage.getPointerPosition(),
      kind: "Canvas",
    } as CanvasPoint;

    if (pointer == null) {
      return;
    }

    const mousePointTo = toLocalCoors(pointer, stage);

    const oldScale = stage.scaleX();
    let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    newScale = Math.max(1, newScale);

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  });

  return stage;
}

function fitToParent(
  stage: Konva.Stage,
  ele: HTMLDivElement,
  diagram: AbodeFloorDiagram
) {
  const vWidth =
    diagram.width > diagram.height ? diagram.width : diagram.height;

  const container = ele;

  const containerWidth = container.offsetWidth;
  stage.width(containerWidth);
  stage.height(containerWidth);

  const scale = containerWidth / vWidth;
  const bgLayer = stage.getLayers()[0];
  if (bgLayer) {
    // const bgGroup = bgLayer.getChildren()[0];
    bgLayer.x(stage.width() / 2);
    bgLayer.y(stage.height() / 2);
    bgLayer.scale({ x: scale, y: scale });
    bgLayer.draw();
  }

  stage.draw();

  const resize = () => {
    const containerWidth = container.offsetWidth;
    stage.width(containerWidth);
    stage.height(containerWidth);
    const scale = containerWidth / vWidth;
    const bgLayer = stage.getLayers()[0];
    if (bgLayer) {
      // const bgGroup = bgLayer.getChildren()[0];
      bgLayer.x(stage.width() / 2);
      bgLayer.y(stage.height() / 2);
      bgLayer.scale({ x: scale, y: scale });
      bgLayer.draw();
    }
    stage.draw();
  };

  window.addEventListener("resize", resize);
}

function stageFromDiagram(ele: HTMLDivElement) {
  const stage = new Konva.Stage({
    container: ele,
    width: ele.offsetWidth,
    height: ele.offsetWidth,
    draggable: true,
  });

  stage.dragBoundFunc((pos) => {
    const wLimit = stage.width() - stage.width() * stage.scaleX();
    const hLimit = stage.height() - stage.height() * stage.scaleY();

    return {
      x: Math.max(wLimit, Math.min(pos.x, 0)),
      y: Math.max(hLimit, Math.min(pos.y, 0)),
    };
  });

  pinchZoom(stage);
  addScaling(stage);

  return stage;
}

function rotateAroundCenter(group: Konva.Group, angle: number) {
  if (angle === 0) {
    return;
  }

  const center = {
    x: group.x() + group.width() / 2,
    y: group.y() + group.height() / 2,
  };

  const angleRadians = (angle * Math.PI) / 180;

  const x =
    center.x +
    (group.x() - center.x) * Math.cos(angleRadians) -
    (group.y() - center.y) * Math.sin(angleRadians);
  const y =
    center.y +
    (group.x() - center.x) * Math.sin(angleRadians) +
    (group.y() - center.y) * Math.cos(angleRadians);

  group.position({ x: x, y: y });

  group.rotation(angle);
}

function roomGroup(layer: Konva.Layer, selected: Ref<number | null>) {
  return (room: AbodeFloorDiagramRoomItem) => {
    const group = new Konva.Group({
      x: room.x,
      y: room.y,
      width: room.width,
      height: room.height,
    });

    const angle = room.angle || 0;
    rotateAroundCenter(group, angle);

    const rect = new Konva.Rect({
      width: room.width,
      height: room.height,
      stroke: "black",
      fill: room.locked ? lockedColor : baseColor,
      strokeWidth: 3,
    });

    group.on("mouseenter", function () {
      if (selected.value === room.roomId || room.locked) {
        return;
      }

      rect.fill(hoverColor);
      layer.draw();
    });

    group.on("mouseleave", function () {
      if (selected.value === room.roomId || room.locked) {
        return;
      }
      rect.fill(baseColor);
      layer.draw();
    });

    const onSelect = function (this: Konva.Group) {
      if (room.locked) return;
      selected.value = room.roomId;
    };

    group.on("click", onSelect);
    group.on("tap", onSelect);

    const labelText = room.locked ? `${room.roomName}` : room.roomName;
    const text = new Konva.Text({
      text: labelText,
      x: room.width / 2,
      y: room.height / 2,
      fontSize: 30,
      fill: room.locked ? "white" : "black",
      rotation: room.angle === 0 ? 0 : -room.angle,
    });
    text.offsetX(text.width() / 2);
    text.offsetY(text.height() / 2);

    group.add(rect);

    group.add(text);

    return { group, room };
  };
}

async function diagramLayer(
  diagram: AbodeFloorDiagram,
  selected: Ref<number | null>,
  stage: Konva.Stage
) {
  const layer = new Konva.Layer();
  const imageObj = new Image();
  imageObj.src = diagram.backgroundImage;
  await imageObj.decode();
  layer.add(
    new Konva.Image({
      x: 0,
      y: 0,
      image: imageObj,
      width: diagram.width,
      height: diagram.height,
    })
  );
  const groupFn = roomGroup(layer, selected);

  const groups = {} as Record<
    number,
    { room: AbodeFloorDiagramRoomItem; group: Konva.Group }
  >;
  diagram.roomItems.forEach((room) => {
    const g = groupFn(room);
    groups[g.room.roomId] = g;

    layer.add(g.group);
  });

  const updateGroup = (
    group: Konva.Group,
    room: AbodeFloorDiagramRoomItem,
    id: number | null
  ) => {
    const rect = group.findOne("Rect") as Konva.Rect;
    const text = group.findOne("Text") as Konva.Text;
    if (room.roomId === id) {
      rect.fill(selectedColor);
      text.fill("white");
    } else {
      rect.fill(baseColor);
      text.fill("black");
    }
  };

  const endWatch = watch(
    selected,
    (next: number | null, prev: number | null) => {
      const nextGroup = groups[next || 0];
      const prevGroup = groups[prev || 0];

      if (nextGroup != null) {
        updateGroup(nextGroup.group, nextGroup.room, next);
      }
      if (prevGroup != null) {
        updateGroup(prevGroup.group, prevGroup.room, next);
      }
    }
  );

  layer.offset({ x: diagram.width / 2, y: diagram.height / 2 });
  layer.x(stage.width() / 2);
  layer.y(stage.height() / 2);
  layer.batchDraw();

  return { layer, endWatch };
}

export const useCanvas = (
  floorPlan: Ref<AbodeFloorDiagram>,
  selected: WritableComputedRef<number | null>,
  konvaDiv: Ref<HTMLDivElement>
) => {
  let endWatch: WatchStopHandle;
  let stage: Konva.Stage;

  const drawFloorPlan = async (stage: Konva.Stage) => {
    const { layer, endWatch: eWatch } = await diagramLayer(
      floorPlan.value,
      selected,
      stage
    );

    stage.add(layer).draw();
    return eWatch;
  };

  onMounted(async () => {
    stage = stageFromDiagram(konvaDiv.value);

    endWatch = await drawFloorPlan(stage);

    fitToParent(stage, konvaDiv.value, floorPlan.value);
  });

  watch(floorPlan, async () => {
    endWatch();
    stage.destroyChildren();

    endWatch = await drawFloorPlan(stage);
    fitToParent(stage, konvaDiv.value, floorPlan.value);
    selected.value = null;
  });

  return {
    zoomIn() {
      zoom(stage, 1);
    },
    zoomOut() {
      zoom(stage, -1);
    },
  };
};
