<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="m-0">
        {{ title }}
      </h3>
      <div>
        <span v-if="isLocked" class="badge ms-3 bg-secondary">
          <i class="bi-lock"></i>
          Locked
        </span>
        <span
          :style="{
            'background-color': groupStatusColor,
            color: '#ffffff',
          }"
          class="badge ms-3"
        >
          <i class="bi-check"></i>
          {{ status }}
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="m-0 h5">
        Code:
        <group-code-tooltip :code="roommateGroupCode" />
      </h4>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import GroupCodeTooltip from "./GroupCodeTooltip/Index.vue";

interface Props {
  roommateGroupCode: string;
  completeStatus: boolean;
  isInvite?: boolean;
  isLocked?: boolean;
}

const props = withDefaults(defineProps<Props>(), { isInvite: false });

const groupStatusColor = computed(() => {
  if (props.completeStatus) {
    return "#468847";
  } else {
    return "#3a87ad";
  }
});

const title = computed(() =>
  props.isInvite ? "Roommate Invitation" : "Roommate Group"
);

const status = computed(() => (props.completeStatus ? "Complete" : "Pending"));
</script>
