<template>
  <section aria-labelledby="future-plan-options" class="card">
    <header id="future-plan-options" class="card-header">
      Future Plans for {{ survey.name }}
    </header>
    <section class="card-body">
      <div v-html="survey.statementText"></div>
      <base-date-time-picker
        v-model="form.expectedCheckoutDt"
        label="Planned checkout"
        name="expectedCheckoutDt"
        div-class="mb-3"
        :min-date="minDate"
        :max-date="maxDate"
        :form-name="formName"
        :rules="rules.expectedCheckoutDt"
      >
        <template #helpText>
          From <strong>{{ minDate.toLocaleString() }} </strong> to
          <strong>{{ maxDate.toLocaleString() }}</strong> (if you need an
          alternate checkout date, please contact your dean)
        </template>
      </base-date-time-picker>
      <template v-if="form.responseType === 'End of Year'">
        <base-button-group-checkbox
          v-model="form.summerPlans"
          label="Are you planning on staying in a residence hall during the summer?"
          name="summerPlans"
          div-class="mb-3"
          :options="[
            { name: 'Yes', value: true },
            { name: 'No', value: false },
          ]"
          :form-name="formName"
          :rules="rules.summerPlans"
        />
        <template v-if="form.summerPlans">
          <base-date-time-picker
            v-model="form.summerStartDt"
            label="Expected Summer Check In Date"
            name="summerStartDt"
            div-class="mb-3"
            :form-name="formName"
            :rules="rules.summerStartDt"
          />
          <base-date-time-picker
            v-model="form.summerEndDt"
            label="Expected Summer Check Out Date"
            :disabled="!form.summerStartDt"
            name="summerEndDt"
            div-class="mb-3"
            :form-name="formName"
            :rules="rules.summerEndDt"
          />
          <base-dropdown
            v-model="form.summerReason"
            div-class="mb-3"
            label="Reason for Summer Stay"
            name="summerReason"
            v-test="'summerReason'"
            :options="['Work', 'School', 'Both Work & School']"
            :form-name="formName"
            :rules="rules.summerReason"
          />
        </template>
      </template>
      <base-radio
        v-model="form.futurePlanOption"
        :label="`${survey.name} I plan to be`"
        name="futurePlanOption"
        :options="surveyOptions"
        return-object
        div-class="mt-2"
        option-label="name"
        :form-name="formName"
        :rules="rules.futurePlanOption"
      >
        <template #optionHelp="{ option }">
          <base-textfield
            :key="option.name as string"
            v-if="option.promptForDetails"
            v-model="form.planDetail"
            class="form-control"
            name="planDetail"
            placeholder="Enter other plans"
            :form-name="formName"
            :rules="rules.planDetail"
          />
          <div
            v-if="option.feedback"
            class="form-text"
            v-html="option.feedback"
          ></div>
        </template>
      </base-radio>
    </section>
  </section>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

import { fromRailsDateTime } from "@/utilities";

import { FuturePlanOption, FuturePlans, FuturePlansSurvey } from "../types";
import { Rules } from "../useValidations";

interface Props {
  survey: FuturePlansSurvey;
  surveyOptions: FuturePlanOption[];
  showSummerPlans: boolean;
  formName: string;
  rules: Rules;
}

const props = defineProps<Props>();

const form = inject("form") as Ref<
  FuturePlans.EndOfTerm | FuturePlans.EndOfYear
>;

const minDate = fromRailsDateTime(props.survey.earliestCheckoutDt);
const maxDate = fromRailsDateTime(props.survey.latestCheckoutDt);
</script>
