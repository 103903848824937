import { computed, reactive, Ref, WritableComputedRef } from "vue";

export function computedModel<T>(
  modelValue: Ref<T>,
  emit: (str: "update:modelValue", mv: T) => void
): WritableComputedRef<T>;
export function computedModel<T, K extends keyof T>(
  modelValue: Ref<T>,
  emit: (str: "update:modelValue", mv: T) => void,
  name: K
): WritableComputedRef<T[typeof name]>;
export function computedModel<T, K extends keyof T>(
  modelValue: Ref<T>,
  emit: (str: "update:modelValue", mv: T) => void,
  name?: K
) {
  if (name) {
    return computed({
      get() {
        return modelValue.value[name];
      },
      set(v: T[typeof name]) {
        emit("update:modelValue", { ...modelValue.value, [name]: v });
      },
    });
  }

  return computed({
    get() {
      return modelValue.value;
    },
    set(v: T) {
      emit("update:modelValue", v);
    },
  });
}

type WritableRefs<T> = { [P in keyof T]: WritableComputedRef<T[P]> };

export function computedModels<T>(
  modelValue: Ref<T>,
  emit: (str: "update:modelValue", mv: T) => void
) {
  const reducer = (acc: WritableRefs<T>, name: keyof T) => ({
    ...acc,
    [name]: computedModel(modelValue, emit, name),
  });

  return (Object.keys(modelValue.value as {}) as Array<keyof T>).reduce<
    WritableRefs<T>
  >(reducer, {} as WritableRefs<T>);
}

export function reactiveModel<T>(
  modelValue: Ref<T>,
  emit: (str: "update:modelValue", mv: T) => void
) {
  return reactive(computedModels<T>(modelValue, emit));
}
