<template>
  <section>
    <Toast />
    <h1 class="border-bottom pb-3 mb-3">
      {{ name }}
    </h1>
    <p v-if="introText" v-html="introText"></p>

    <fade-transition v-if="!store.loading" mode="out-in">
      <div v-if="formType === null" class="mb-3">
        <div
          style="
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            grid-auto-rows: 1fr;
            grid-column-gap: 1rem;
          "
        >
          <div
            v-for="tab in formTabs"
            :key="tab.title"
            class="card text-center mb-3"
          >
            <div
              style="display: grid; grid-template-columns: 1fr 1fr 1fr"
              class="card-header"
            >
              <div class="d-flex align-items-center me-3">
                <span v-if="tab.default" class="badge bg-secondary">
                  Default
                </span>
              </div>
              <div class="align-middle text-nowrap">
                {{ tab.title }}
              </div>
              <div></div>
            </div>
            <div class="card-body d-flex flex-column">
              <div
                class="d-flex align-items-center justify-content-center mb-3"
              >
                <component :is="tab.icon" width="4rem" height="4rem" />
              </div>
              <div v-if="tab.infoText" v-html="tab.infoText"></div>
              <div
                class="
                  flex-grow-1
                  d-flex
                  justify-content-center
                  align-items-end
                "
              >
                <button class="btn btn-primary" @click="tab.go">Choose</button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showRoomAlone && inconspicuousRoomAlone" class="col-12">
          <a class="link-secondary" @click="store.updateFormType('single')">
            Request to Room Alone
          </a>
        </div>
      </div>
      <div v-else>
        <component
          :is="store.activeFormComponent"
          v-if="store.activeFormComponent"
        />
      </div>
    </fade-transition>
  </section>
</template>

<script setup lang="ts">
import {
  BIconPeopleFill,
  BIconPersonFill,
  BIconPersonLinesFill,
} from "bootstrap-icons-vue";
import { storeToRefs } from "pinia";
import Toast from "primevue/toast";
import { watch } from "vue";

import { useStore } from "./store";

interface Props {
  housingPoolMemberId: number;
  name: string;
  schoolId: string;
  showRoomAlone: boolean;
  inconspicuousRoomAlone: boolean;
  showChooseRoommate: boolean;
  showAssignedRoommate: boolean;
  chooseRoommateText: string;
  assignedRoommateText: string;
  introText?: string | null;
}

const props = defineProps<Props>();

const store = useStore();
const { fetchGroupInfo } = store;
const { formType } = storeToRefs(store);

store.$patch((state) => {
  state.housingPoolMemberId = props.housingPoolMemberId;
  state.schoolId = props.schoolId;
});

store.loading = true;
fetchGroupInfo().finally(() => {
  store.loading = false;
});

const formTabs = [
  {
    title: "Choose Roommate",
    icon: BIconPeopleFill,
    go: () => {
      store.updateFormType("choose");
    },
    infoText: props.chooseRoommateText,
    show: props.showChooseRoommate,
  },
  {
    title: "Room Alone",
    icon: BIconPersonFill,
    go: () => {
      store.updateFormType("single");
    },
    infoText: "Request to Room Alone",
    show: props.showRoomAlone && !props.inconspicuousRoomAlone,
  },
  {
    title: "Assigned Roommate",
    icon: BIconPersonLinesFill,
    go: () => {
      store.updateFormType("assigned");
    },
    infoText: props.assignedRoommateText,
    show: props.showAssignedRoommate,
    default: true,
  },
].filter((t) => t.show);

watch(
  () => store.formType,
  async (newValue, oldValue) => {
    if (oldValue !== "assigned" && newValue === "assigned") {
      store.updateAutoAssigned(true);
    } else if (oldValue === "assigned" && newValue !== "assigned") {
      store.updateAutoAssigned(false);
    }
  }
);
</script>
