<template>
  <div class="mb-3">
    <student-info-form
      :model-value="props.modelValue"
      :email="email"
      :form-name="formName"
      :rules="rules"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
  <contact-info-form
    :contact1="props.modelValue.contact1"
    :contact2="props.modelValue.contact2"
    :form-name="formName"
    :rules="rules"
    :states="states"
    :countries="countries"
    @update:contact1="
      $emit('update:modelValue', { ...props.modelValue, contact1: $event })
    "
    @update:contact2="
      $emit('update:modelValue', { ...props.modelValue, contact2: $event })
    "
  />
</template>

<script setup lang="ts">
import { computed, toRefs } from "vue";

import { CountryOptions, StateOptions } from "@/shared/BaseAddress.vue";
import {
  ContactInfoForm,
  ContactInformation,
  rules as contactRules,
} from "@/views/ContactInfo/components/ContactInfoForm";
import {
  rules as studentRules,
  StudentInfo,
  StudentInfoForm,
} from "@/views/Students/components/StudentInfoForm";

interface Props {
  modelValue: StudentInfo & ContactInformation;
  email: string;
  states: StateOptions;
  countries: CountryOptions;
  formName: string;
}

type Emits = {
  (e: "update:modelValue", payload: StudentInfo & ContactInformation): void;
};

defineEmits<Emits>();

const props = defineProps<Props>();

const { modelValue } = toRefs(props);

const rules = computed(() => ({
  ...contactRules(modelValue.value),
  ...studentRules(),
}));
</script>
