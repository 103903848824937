import axios from "axios";
import { snakeCase } from "lodash";
import { get, omit, pipe, update } from "lodash/fp";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";

import { applications, contactInfo } from "@/api";
import { stripPhoneNumber, toRailsDateTime } from "@/utilities";
import { useForm } from "@/utilities/validations";

import { Props } from "./Index.vue";
import { Application } from "./types";

const maybeApply = <T, V extends NonNullable<T>, R>(v: T, fn: (v: V) => R) =>
  v ? fn(v as V) : v;

const sanatizeApplication = pipe(
  omit("emergencyContacts"),
  (app: Application) => ({
    ...app,
    homephone: stripPhoneNumber(app.homephone || ""),
    cellphone: stripPhoneNumber(app.cellphone || ""),
    summerphone: stripPhoneNumber(app.summerphone || ""),
    arrivalDt: maybeApply(app.arrivalDt, toRailsDateTime),
    priority1: maybeApply(app.priority1, snakeCase),
    priority2: maybeApply(app.priority2, snakeCase),
    priority3: maybeApply(app.priority3, snakeCase),
  })
);

const sanatizeContacts = pipe(
  get("emergencyContacts"),
  (data) => (data.contact2.contactName ? data : omit(["contact2"], data)),
  update("contact1.phone1", stripPhoneNumber),
  update("contact1.phone2", stripPhoneNumber),
  update("contact1.phone3", stripPhoneNumber),
  (data) =>
    data.contact2
      ? pipe(
          update("contact2.phone1", stripPhoneNumber),
          update("contact2.phone2", stripPhoneNumber),
          update("contact2.phone3", stripPhoneNumber)
        )(data)
      : data
);

export function useSubmission(form: Application, props: Readonly<Props>) {
  const loading = ref(false);
  const toast = useToast();

  const formName = "application-form";
  const { submit: submitForm, setFieldErrorMessage } = useForm(formName);

  const showFormError = () => {
    toast.add({
      severity: "error",
      summary: "Errors in form",
      detail: "You seem to have a few errors in your form",
      life: 5000,
    });
  };

  const submit = submitForm(
    async () => {
      loading.value = true;

      const application = sanatizeApplication(form);
      const contacts = sanatizeContacts(form);

      try {
        let result;

        if (props.formType === "new") {
          result = await applications.create({
            data: { application },
          });
        } else {
          result = await applications.update({
            params: { id: application.appId },
            data: { application },
          });
        }

        if (
          props.formOptions.fieldSpecs["emergencyContacts"] !== undefined &&
          ["Required", "Optional"].includes(
            props.formOptions.fieldSpecs["emergencyContacts"].formState
          )
        ) {
          await contactInfo.update({
            params: { id: props.studentId },
            data: contacts,
          });
        }

        window.location.assign(result.location);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.data.location) {
            window.location.assign(err.response.data.location);
          } else {
            Object.entries(err.response.data).forEach(([key, val]) => {
              setFieldErrorMessage(key, (val as string[]).join(", "));
            });
            showFormError();
          }
        }
      } finally {
        loading.value = false;
      }
    },
    async () => showFormError()
  );

  return { submit, loading, formName };
}
