// JsFromRoutes CacheKey 58f01f82015670a624f5edd0149c12fb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const moveIn = {
  currentStep: definePathHelper("get", "/move_in/current_step"),
  create: definePathHelper("post", "/move_in"),
  new: definePathHelper("get", "/move_in/new"),
};
