import axios from "axios";
import Cookies from "js-cookie";
import { defineStore } from "pinia";
import { ToastServiceMethods } from "primevue/toastservice";

import AssignedRoommateForm from "./components/AssignedRoommateForm.vue";
import ChooseRoommateForm from "./components/ChooseRoommateForm/Index.vue";
import RoomAloneForm from "./components/RoomAloneForm.vue";
import {
  FormType,
  MemberInviteForm,
  RoomAloneStatus,
  RoommateGroupType,
} from "./types";

export const useStore = defineStore("roommate-groups", {
  state: () => ({
    housingPoolMemberId: 0,
    formType: null as FormType | null,
    schoolId: "",
    loading: false,
    roomAlone: {
      status: null as RoomAloneStatus,
    },
    chooseRoommate: {
      currentTab: "current" as "current" | "pending" | "declined",
      currentGroup: null as RoommateGroupType | null,
      invitations: {
        pending: [] as RoommateGroupType[],
        declined: [] as RoommateGroupType[],
      },

      dialogs: {
        joinGroup: false,
        invite: false,
        leaveGroup: false,
      },
    },
  }),

  getters: {
    activeFormComponent: (state) => {
      switch (state.formType) {
        case "choose":
          return ChooseRoommateForm;
        case "assigned":
          return AssignedRoommateForm;
        case "single":
          return RoomAloneForm;
        default:
          return null;
      }
    },
  },

  actions: {
    updateFormType(payload: FormType | null) {
      this.formType = payload;

      if (payload === null) {
        Cookies.remove("roommate-group-formType", {
          path: "/roommate_groups/new",
        });
      } else {
        Cookies.set("roommate-group-formType", payload, {
          path: "/roommate_groups/new",
        });
      }
    },

    async fetchGroupInfo() {
      try {
        const data = await this.api.roommateGroups.groupsForStudent({
          query: { housingPoolMemberId: this.housingPoolMemberId },
        });

        let formType: FormType | null;

        if (data.formType) {
          formType = data.formType;
          Cookies.set("roommate-group-formType", data.formType, {
            path: "/roommate_groups/new",
          });
        } else {
          formType =
            (Cookies.get("roommate-group-formType") as FormType | undefined) ??
            null;
        }

        this.$patch((state) => {
          state.roomAlone.status = data.roomAloneStatus;
          state.formType = formType;
          state.chooseRoommate.currentGroup = data.current;
          state.chooseRoommate.invitations.pending = data.invited;
          state.chooseRoommate.invitations.declined = data.declined;
        });
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async inviteNewMember(form: MemberInviteForm) {
      try {
        if (this.chooseRoommate.currentGroup === null) {
          await this.api.roommateGroups.invite({
            data: {
              invitee: form,
              housingPoolMemberId: this.housingPoolMemberId,
            },
          });
        } else {
          await this.api.roommateGroups.invite({
            data: {
              invitee: form,
              roommateGroupId: this.chooseRoommate.currentGroup.roommateGroupId,
            },
          });
        }

        await this.fetchGroupInfo();
        this.toast.add({
          summary: "Your invitation has been sent",
          severity: "success",
          life: 5000,
        });
      } catch (e) {
        showError(e, this.toast);
      } finally {
        this.chooseRoommate.dialogs.invite = false;
      }
    },

    async resendEmail(id: number) {
      try {
        await this.api.roommateGroupMembers.resendEmail({
          roommateGroupMemberId: id,
        });

        this.toast.add({
          summary: "Email sent",
          severity: "success",
          life: 5000,
        });
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async updateAutoAssigned(value: boolean) {
      try {
        await this.api.roommateGroups.updateAutoAssigned({
          data: {
            assignedRoommate: value,
            housingPoolMemberId: this.housingPoolMemberId,
          },
        });
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async joinGroup(groupCode: string) {
      try {
        await this.api.roommateGroups.acceptInvitation({ data: { groupCode } });
        this.chooseRoommate.dialogs.joinGroup = false;
        await this.fetchGroupInfo();
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async switchGroups(groupCode: string) {
      try {
        await this.api.roommateGroups.leaveGroup({
          params: {
            roommateGroupId: this.chooseRoommate.currentGroup?.roommateGroupId,
          },
          data: {
            schoolId: this.schoolId,
          },
        });

        await this.api.roommateGroups.acceptInvitation({
          data: { groupCode },
        });

        this.chooseRoommate.dialogs.leaveGroup = false;

        await this.fetchGroupInfo();
      } catch (e) {
        showError(e, this.toast);
      }
    },
    async leaveGroup(schoolId?: string) {
      try {
        await this.api.roommateGroups.leaveGroup({
          params: {
            roommateGroupId: this.chooseRoommate.currentGroup?.roommateGroupId,
          },
          data: {
            schoolId: schoolId ?? this.schoolId,
          },
        });

        this.chooseRoommate.dialogs.leaveGroup = false;

        await this.fetchGroupInfo();
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async acceptInvitation(groupCode: string) {
      if (this.chooseRoommate.currentGroup) {
        this.chooseRoommate.dialogs.leaveGroup = true;
        return;
      }

      try {
        await this.api.roommateGroups.acceptInvitation({ data: { groupCode } });
        await this.fetchGroupInfo();
        this.chooseRoommate.currentTab = "current";
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async declineInvitation(groupCode: string) {
      try {
        await this.api.roommateGroups.declineInvitation({
          data: { groupCode },
        });
        await this.fetchGroupInfo();
      } catch (e) {
        showError(e, this.toast);
      }
    },

    async requestRoomAlone() {
      await this.api.roommateGroups.requestRoomAlone({
        data: {
          housingPoolMemberId: this.housingPoolMemberId,
        },
      });
      this.roomAlone.status = "Requested";
    },

    async cancelRoomAlone() {
      await this.api.roommateGroups.cancelRoomAlone({
        data: {
          housingPoolMemberId: this.housingPoolMemberId,
        },
      });
      this.roomAlone.status = "Not Requested";
    },
  },
});

const showError = (error: unknown, toast: ToastServiceMethods) => {
  if (
    axios.isAxiosError(error) &&
    error.response &&
    error.response.data.errors &&
    Array.isArray(error.response.data.errors)
  ) {
    toast.add({
      summary: "Error",
      detail: error.response.data.errors.join(", "),
      severity: "error",
      life: 5000,
    });
  } else {
    console.log({ error });
  }
};
