<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :optional="optional"
  >
    <Calendar
      v-bind="$attrs"
      :id="innerId"
      v-model="jsDate"
      :class="{ 'w-100': true, 'p-invalid is-invalid': showError }"
      :min-date="innerMinDate"
      :max-date="innerMaxDate"
      :show-other-months="true"
      :select-other-months="true"
      year-navigator
      show-icon
      :touch-u-i="isMobile"
      autocomplete="off"
    />
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    >
      <slot name="helpText" />
    </help-text>
  </base-field>
</template>

<script lang="ts">
export default {
  name: "BaseDateTimePicker",
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { DateTime } from "luxon";
import Calendar from "primevue/calendar";
import { computed, nextTick, toRefs, watch } from "vue";

import { computedModel } from "@/utilities";
import { useBreakpoints } from "@/utilities/breakpoints";
import { useField, Validators } from "@/utilities/validations";

type ModelValue = DateTime | null;

interface Props {
  name: string;
  id?: string;
  modelValue?: ModelValue;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<ModelValue>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  id: "",
  minDate: null,
  maxDate: null,
  rules: () => [],
});

const emit = defineEmits<{
  (e: "update:modelValue", value: ModelValue): void;
}>();

const innerId = computed(() => props.id || props.name);

const { rules, modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const { touched, validate, errorMessage, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

const jsDate = computed<Date | undefined>({
  get: () => model.value?.toJSDate(),
  set: (val) => (model.value = val ? DateTime.fromJSDate(val) : null),
});

watch(model, () => {
  nextTick(() => validate());
});

const innerMaxDate = computed(() => props.maxDate?.toJSDate());

const innerMinDate = computed(() => props.minDate?.toJSDate());

const { smaller } = useBreakpoints();
const isMobile = smaller("md");
</script>

<style scoped lang="scss">
input.form-control[readonly] {
  background-color: #ffffff;
}
</style>
