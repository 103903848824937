import { merge } from "lodash-es";
import { useToast } from "primevue/usetoast";
import { Ref, ref } from "vue";

import {
  ApiInjectionKey,
  safeInject,
  stripPhoneNumber,
  toRailsDateTime,
} from "@/utilities";
import { useForm } from "@/utilities/validations";

import { FuturePlans } from "./types";
import { useValidations } from "./useValidations";

export function useSubmission(form: Ref<FuturePlans.Form>) {
  const toast = useToast();

  const formName = "future-plans-form";
  const { rules } = useValidations();
  const loading = ref(false);

  const { submit: handleSubmit, reset: resetForm } = useForm(formName);

  const api = safeInject(ApiInjectionKey);

  const submit = handleSubmit(async () => {
    try {
      loading.value = true;
      let data;
      if (form.value.responseType === "End of Term") {
        data = endOfTermData(form.value);
      } else {
        data = endOfYearData(form.value);
      }

      const { location } = await api.futurePlanResponses.create({ data });
      window.location.assign(location);
    } catch (e) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something seems to have gone wrong, please contact your dean",
        life: 3000,
      });
    } finally {
      loading.value = false;
    }
  });

  return { resetForm, rules, formName, loading, submit };
}

function endOfYearData(form: FuturePlans.EndOfYear) {
  let data: { [index: string]: unknown } = {
    responseType: form.responseType,
    futurePlanOptionId: form.futurePlanOption?.value,
    expectedCheckoutDt: toRailsDateTime(form.expectedCheckoutDt!),
  };

  if (form.futurePlanOption?.promptForDetails) {
    data.planDetail = form.planDetail;
  }

  if (form.summerPlans) {
    data.summerStartDt = toRailsDateTime(form.summerStartDt!);
    data.summerEndDt = toRailsDateTime(form.summerEndDt!);
    data.summerReason = form.summerReason;
  }

  data = merge(data, {
    forwardingAddress: {
      ...form.forwardingAddress,
      phone: stripPhoneNumber(form.forwardingAddress.phone),
    },
  });

  return data;
}

function endOfTermData(form: FuturePlans.EndOfTerm) {
  if (form.stayingInRoom) {
    if (form.changeHousing) {
      return {
        responseType: form.responseType,
        housingChangeRequests: form.housingChangeRequests,
      };
    } else {
      return {
        responseType: form.responseType,
        housingChangeRequests: form.housingChangeRequests,
      };
    }
  } else {
    let data = {
      futurePlanOptionId: form.futurePlanOption?.value,
      responseType: form.responseType,
      expectedCheckoutDt: toRailsDateTime(form.expectedCheckoutDt!),
    } as Record<string, unknown>;

    if (form.futurePlanOption?.promptForDetails) {
      data.planDetail = form.planDetail;
    }

    if (form.futurePlanOption?.planCategory !== "Resident") {
      data = merge(data, {
        forwardingAddress: {
          ...form.forwardingAddress,
          phone: stripPhoneNumber(form.forwardingAddress.phone),
        },
      });
    }

    return data;
  }
}
