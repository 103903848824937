<template>
  <card-to-mobile-layout v-if="formFields.length" title="Emergency Contacts">
    <div class="row">
      <template v-for="field in formFields" :key="field.attrs.name">
        <component
          :is="field.comp"
          :model-value="form[field.attrs.name]"
          v-bind="field.attrs"
          :rules="unref(field.rules)"
          :form-name="formName"
          @update:model-value="form[field.attrs.name] = $event"
        />
      </template>
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { inject, unref } from "vue";

import { Application } from "../types";
import { GeneratedField } from "../useFieldGenerator";

interface Props {
  formName: string;
  formFields: GeneratedField[];
}

defineProps<Props>();

const form = inject("form") as Application;
</script>
