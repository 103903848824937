<template>
  <emergency-contact-form
    v-model="contact1"
    :form-name="formName"
    title="Primary Emergency Contact"
    name="emergencyContact1"
    :rules="rules.contact1"
    :states="states"
    :countries="countries"
  />
  <emergency-contact-form
    v-model="contact2"
    :form-name="formName"
    title="Secondary Emergency Contact"
    optional
    name="emergencyContact2"
    :rules="rules.contact2"
    :states="states"
    :countries="countries"
  />
</template>

<script lang="ts">
export default {
  name: "ContactInfoForm",
};
</script>

<script setup lang="ts">
import { useVModels } from "@vueuse/core";

import { CountryOptions, StateOptions } from "@/shared/BaseAddress.vue";

import EmergencyContactForm from "./components/EmergencyContactForm.vue";
import { ContactInformationRules, EmergencyContact } from "./types";

interface Props {
  contact1: EmergencyContact;
  contact2: EmergencyContact;
  formName: string;
  rules: ContactInformationRules;
  states: StateOptions;
  countries: CountryOptions;
}

interface Emits {
  (e: "update:contact1", payload: EmergencyContact): void;
  (e: "update:contact2", payload: EmergencyContact): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const { contact1, contact2 } = useVModels(props, emit);
</script>
