<template>
  <button type="button" class="btn btn-secondary mb-3" @click="populateHome">
    Going Home
  </button>

  <card-to-mobile-layout class="mb-4" title="Leave Dates">
    <div class="row">
      <base-date-picker
        v-model="form.departDate"
        div-class="col-12 col-sm-6"
        name="departDate"
        label="Depart Date"
        :form-name="formName"
        :min-date="minDate"
        :max-date="maxDate"
        :rules="rules.departDate as any"
      />
      <base-time-picker
        v-model="form.departTime"
        div-class="col-12 col-sm-6"
        name="departTime"
        label="Depart Time"
        :form-name="formName"
        :min-date="minDate"
        :max-date="maxDate"
        :rules="rules.departTime as any"
      />
      <base-date-picker
        v-model="form.returnDate"
        div-class="col-12 col-sm-6"
        label="Return Date"
        name="returnDate"
        :form-name="formName"
        :min-date="form.departDate"
        :max-date="maxDate"
        :rules="rules.returnDate as any"
        :disabled="form.departDate == null"
      />
      <base-time-picker
        v-model="form.returnTime"
        div-class="col-12 col-sm-6"
        name="returnTime"
        label="Return Time"
        :form-name="formName"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled="form.departDate == null"
        :rules="rules.returnTime as any"
      />
    </div>
  </card-to-mobile-layout>
  <card-to-mobile-layout class="mb-4" title="Destination">
    <div class="row">
      <base-textfield
        v-model="form.destination"
        name="destination"
        div-class="col-sm-6 col-12 mb-3"
        label="Name of Host"
        :rules="rules.destination"
        :form-name="formName"
      />
      <base-textfield
        v-model="form.hostRelationship"
        name="hostRelationship"
        div-class="col-sm-6 col-12 mb-3"
        label="Relationship of Host"
        :rules="rules.hostRelationship"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-dropdown
        v-model="form.hostAge"
        label="Host Age"
        name="hostAge"
        div-class="col-sm-6 col-12 mb-3"
        :options="['17 or under', '18 to 22', '+23']"
        show-clear
        :rules="rules.hostAge"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.hostphone"
        name="hostphone"
        div-class="col-sm-6 col-12 mb-3"
        label="Host Phone"
        :rules="rules.hostphone"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.address"
        name="address"
        div-class="col-12 mb-3"
        label="Address"
        :rules="rules.address"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.city"
        name="city"
        div-class="col-sm-6 col-12 mb-3"
        label="City"
        :rules="rules.city"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-dropdown
        v-model="form.state"
        label="State"
        name="state"
        div-class="col-sm-3 col-12 mb-3"
        option-label="label"
        option-value="value"
        :options="formOptions.states"
        show-clear
        :rules="rules.state"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.postal"
        name="postal"
        div-class="col-sm-3 col-12 mb-3"
        label="ZIP"
        optional
      />
    </div>
  </card-to-mobile-layout>
  <card-to-mobile-layout class="mb-4" title="Transportation">
    <div class="row">
      <base-textfield
        v-model="form.transport"
        name="transport"
        div-class="col-sm-6 col-12 mb-3"
        label="Method of transportation"
        :rules="rules.transport"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.driver"
        name="driver"
        div-class="col-sm-6 col-12 mb-3"
        label="Driver"
        optional
      />
      <base-textfield
        v-model="form.companions"
        name="companions"
        div-class="col-sm-6 col-12 mb-3"
        label="Companions"
        optional
      />
    </div>
  </card-to-mobile-layout>
  <card-to-mobile-layout class="mb-4" title="Other">
    <div class="row">
      <base-textfield
        v-model="form.cellphone"
        name="cellphone"
        div-class="col-sm-6 col-12 mb-3"
        label="Your Cell Phone"
        :rules="rules.cellphone"
        :form-name="formName"
        :optional="formOptions.simplified"
      />
      <base-textfield
        v-model="form.reason"
        div-class="col-sm-6 col-12 mb-3"
        label="Reason"
        name="reason"
        :rules="rules.reason"
        :form-name="formName"
      />
      <base-textfield
        v-model="form.ccParent"
        name="ccParent"
        div-class="col-sm-6 col-12 mb-3"
        label="Parent Email"
        :rules="rules.ccParent"
        :form-name="formName"
        optional
      />
    </div>
  </card-to-mobile-layout>
</template>

<script lang="ts">
export default {
  name: "LeaveForm",
};
</script>

<script setup lang="ts">
import { computed, watch } from "vue";

import BaseDatePicker from "@/shared/BaseDatePicker.vue";
import BaseTimePicker from "@/shared/BaseTimePicker.vue";
import { fromRailsDateTime, safeInject } from "@/utilities";

import { FormOptions, LeaveForm, leaveFormKey } from "./types";
import { useValidations } from "./useValidations";

export interface Props {
  formOptions: FormOptions;
  modelValue: LeaveForm;
  formName: string;
}

const props = defineProps<Props>();

const form: LeaveForm = safeInject(leaveFormKey);

const maxDate = computed(() => fromRailsDateTime(props.formOptions.maxDate));
const minDate = computed(() => fromRailsDateTime(props.formOptions.minDate));

const { rules } = useValidations(
  props.modelValue,
  props.formOptions.simplified
);

watch(
  () => form.departDate,
  () => {
    form.returnDate = null;
    form.returnTime = null;
  }
);

const populateHome = () => {
  form.destination = "Home";
  form.hostRelationship = "Home";
  form.address = props.formOptions.homeAddress.address;
  form.city = props.formOptions.homeAddress.city;
  form.postal = props.formOptions.homeAddress.postal;
  form.state = props.formOptions.homeAddress.state;
  form.hostphone = props.formOptions.homeAddress.hostphone;
  form.cellphone = props.formOptions.homeAddress.cellphone;
};
</script>
