<template>
  <Toast />
  <h1 v-once>Future Plans for {{ survey.name }}</h1>
  <hr />
  <dl v-once>
    <div class="row pb-3">
      <dt class="col-12 col-sm-2 text-muted">Your Name</dt>
      <dd class="col-12 col-sm-10 m-0">{{ studentName }}</dd>
    </div>
    <div class="row">
      <dt class="col-12 col-sm-2 text-muted">Class Standing</dt>
      <dd class="col-12 col-sm-10 m-0">{{ classStanding }}</dd>
    </div>
  </dl>

  <form @submit.prevent="submit">
    <end-of-year-form
      v-if="isEndOfYear(form)"
      :states="states"
      :countries="countries"
      :survey-options="surveyOptions"
      :survey="survey"
      :form-name="formName"
      :rules="rules"
    />
    <end-of-term-form
      v-else
      :states="states"
      :countries="countries"
      :survey-options="surveyOptions"
      :survey="survey"
      :current-building="currentBuilding"
      :form-name="formName"
      :rules="rules"
    />
    <div class="col-12 pb-3 d-flex">
      <base-submit-button
        :loading="loading"
        class="btn btn-primary btn-lg flex-grow-1 flex-md-grow-0"
      />
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: "FuturePlansResponse",
};
</script>

<script setup lang="ts">
import Toast from "primevue/toast";
import { provide, Ref, ref } from "vue";

import { CountryOptions, StateOptions } from "@/shared/BaseAddress.vue";

import EndOfTermForm from "./components/EndOfTermForm.vue";
import EndOfYearForm from "./components/EndOfYearForm.vue";
import {
  FuturePlanOption,
  FuturePlans,
  FuturePlansSurvey,
  isEndOfYear,
  newEndOfTermForm,
  newEndOfYearForm,
} from "./types";
import { useReset } from "./useReset";
import { useSubmission } from "./useSubmission";

export interface Props {
  survey: FuturePlansSurvey;
  studentName: string;
  classStanding: string;
  surveyOptions: FuturePlanOption[];
  states: StateOptions;
  countries: CountryOptions;
  currentBuilding: string;
}

const props = defineProps<Props>();

const form: Ref<FuturePlans.Form> = ref(
  props.survey.type === "End of Year" ? newEndOfYearForm() : newEndOfTermForm()
);

provide("form", form);

const { rules, resetForm, formName, submit, loading } = useSubmission(form);

useReset(form, resetForm);
</script>
