<template>
  <div class="page-header mb-3">
    <h1>Housing Application</h1>
  </div>
  <fade-transition mode="out-in">
    <div class="row">
      <div v-if="showApplicationSelect" key="selector" class="col-12">
        <housing-pool-selector
          label="Which eligible housing group would you like to join?"
          selection-text="Apply Now"
          :housing-pools="housingPools"
        />
      </div>
      <template v-else>
        <div key="main" class="col-12">
          <housing-pool-member-list :members="housingPoolMembers" />
        </div>
        <div class="col-12">
          <status-list :applications="currentApplications" />
        </div>
        <div class="col-12">
          <div
            v-if="showApplicationText"
            class="alert alert-info text-nowrap d-flex align-items-center"
            style="max-width: min-content"
            role="alert"
          >
            <i style="font-size: 1.5rem" class="bi bi-info-circle me-3"></i>
            <span v-html="applicationText"></span>
          </div>
          <button
            v-if="housingPools.length !== 0"
            class="btn btn-primary mb-3"
            @click="showApplicationSelect = true"
          >
            Apply Now
          </button>
          <p v-else>
            <strong>
              You are not eligible to create a new application at this time.
            </strong>
          </p>
        </div>
        <div class="col-12">
          <history-list :applications="previousApplications" />
        </div>
      </template>
    </div>
  </fade-transition>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { HousingPool } from "@/shared/HousingPoolSelector";

import HistoryList from "./components/HistoryList.vue";
import HousingPoolMemberList from "./components/HousingPoolMemberList.vue";
import StatusList from "./components/StatusList.vue";
import { Application, HousingPoolMember } from "./types";

interface Props {
  housingPoolMembers: Array<HousingPoolMember>;
  currentApplications: Array<Application>;
  previousApplications?: Array<Application>;
  housingPools: Array<HousingPool>;
  applicationText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  currentApplications: () => [],
  previousApplications: () => [],
  housingPools: () => [],
});

const showApplicationSelect = ref(false);

const showApplicationText = computed(
  () =>
    props.applicationText != null && props.applicationText.trim().length != 0
);
</script>
