<template>
  <article :class="{ 'housing-pool card': true, locked: housingPool.locked }">
    <img
      v-if="
        housingPool.housingPoolImage != null &&
        housingPool.housingPoolImage != ''
      "
      class="housing-pool__img"
      :src="`data:image/jpg;base64, ${housingPool.housingPoolImage}`"
    />
    <section class="housing-pool__text">
      <p v-if="housingPool.locked" class="text-danger">
        This housing pool requires approval
      </p>
      <div class="d-flex flex-column">
        <h1 class="housing-pool__title">
          {{ housingPool.name }}
        </h1>
        <p class="flex-grow-1">
          {{ housingPool.description }}
        </p>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="housing-pool__term h5" style="margin-right: 2rem">
          {{ housingPool.termName }}
        </h2>
        <a
          :class="{
            'btn btn-lg btn-primary text-nowrap': true,
            disabled: housingPool.locked,
          }"
          :href="housingPool.url"
          @click="confirm"
        >
          {{ selectionText }}
        </a>
      </div>
    </section>
    <v-dialog
      v-if="
        !!props.housingPool.confirmationText &&
        !!props.housingPool.requiresConfirmation
      "
      v-model:visible="dialogOpen"
      modal
      :show-header="false"
      content-class="rounded "
      style="max-width: 50ch"
      position="center"
    >
      <div class="d-flex flex-column">
        <section v-html="props.housingPool.confirmationText"></section>
        <section style="gap: 0.75rem" class="pt-3 d-flex justify-content-end">
          <button class="btn btn-secondary" @click="dialogOpen = false">
            No
          </button>
          <a class="btn btn-primary" :href="housingPool.url">Yes</a>
        </section>
      </div>
    </v-dialog>
  </article>
</template>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { ref } from "vue";

import { HousingPool } from "../types";

interface Props {
  housingPool: HousingPool;
  selectionText: string;
}

const props = defineProps<Props>();

const dialogOpen = ref(false);
const confirm = (event: Event) => {
  if (props.housingPool.requiresConfirmation) {
    event.preventDefault();
    dialogOpen.value = true;
  }
};
</script>

<style lang="scss" scoped>
.material-design-icon {
  height: 24px;
}

.housing-pool {
  position: relative;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  overflow: hidden;

  .panel-body {
    padding: 0;
  }

  .check-circle-outline-icon {
    display: none;
  }

  &__title {
    font-size: 2rem;
    margin-top: unset;
    margin-bottom: 1rem;
  }

  &.locked &__text > * {
    opacity: 0.65;
  }

  &__text {
    padding: 1.5rem;

    > p:first-child {
      opacity: 1;
    }
  }

  &__img {
    width: 100%;
    padding: unset;

    object-fit: cover;
  }

  &__term {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;

    .housing-pool__img {
      width: 35%;
    }
  }
}
</style>
