import { intersection, isEqual, merge } from "lodash";
import { computed } from "vue-demi";
import { array, BaseSchema, boolean, mixed, number, string } from "yup";

import { Validators } from "@/utilities/validations";
import { phoneNumber } from "@/utilities/validators";
import {
  ContactInfoForm,
  ContactInformation,
  rules as contactRules,
} from "@/views/ContactInfo/components/ContactInfoForm";

import { Application, FormOptions } from "./types";

export interface GeneratedField {
  comp: string;
  rules: Validators<unknown>;
  attrs: {
    [str: string]: unknown;
    name: keyof Application;
  };
}

export function useFieldGenerator(form: Application, formOptions: FormOptions) {
  const checkRequired =
    (schema: BaseSchema, array = false) =>
    (required: boolean) => {
      if (array) {
        //TODO: Make this check the schema type instead
        //@ts-ignore
        return required ? schema.min(1, "required") : schema;
      } else {
        return required ? schema.required("required") : schema;
      }
    };

  const possibleFields = {
    schoolid: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    firstName: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    middleName: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    lastName: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    gender: {
      comp: "BaseRadio",
      rules: checkRequired(mixed().oneOf(["M", "F"]).nullable()),
      attrs: {
        "option-id": "value",
        "option-label": "name",
        options: [
          { name: "Male", value: "M" },
          { name: "Female", value: "F" },
        ],
      },
    },
    dateOfBirth: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    highschool: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    classification: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    email: {
      comp: "BaseTextfield",
      rules: checkRequired(string().email().nullable()),
    },
    homephone: {
      comp: "BaseTextfield",
      rules: checkRequired(phoneNumber().nullable()),
    },
    cellphone: {
      comp: "BaseTextfield",
      rules: checkRequired(phoneNumber().nullable()),
    },
    allowSms: {
      comp: "BaseRadio",
      rules: checkRequired(boolean().nullable()),
      attrs: {
        "option-id": "value",
        "option-label": "name",
        options: [
          { name: "Yes", value: true },
          { name: "No", value: false },
        ],
      },
    },
    isTransfer: {
      comp: "BaseDropdown",
      rules: checkRequired(boolean().nullable()),
      attrs: {
        "option-label": "name",
        "option-value": "value",
        options: [
          { name: "Yes", value: true },
          { name: "No", value: false },
        ],
      },
    },
    summerphone: {
      comp: "BaseTextfield",
      rules: checkRequired(phoneNumber().nullable()),
    },
    arrivalDt: {
      comp: "BaseDateTimePicker",
      rules: checkRequired(string().nullable()),
    },
    hasTransport: {
      comp: "BaseCheckBox",
      rules: checkRequired(boolean().nullable()),
      attrs: {
        single: true,
      },
    },
    // -----
    major: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
    },
    classLoad: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.classLoads,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    study: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.studyLevels,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    neatness: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.neatnessLevels,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    bedtime: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.bedtimes,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    waketime: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.waketimes,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    sleepLevel: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.sleepLevels,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    windows: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.windowsOptions,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    roomTemp: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.roomTemps,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    lifestyle: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.lifestyles,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    religious: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.religiousLevels,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    sabbathKeeping: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.sabbathKeepingOptions,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    hobbyIds: {
      comp: "BaseMultiSelect",
      rules: checkRequired(array().of(number()).nullable(), true),
      attrs: {
        options: formOptions.hobbies,
        "help-text": "choose all that apply",
        display: "chip",
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    // TODO: Clean this up, maybe make a better system for validations here
    likedMusicIds: {
      comp: "BaseMultiSelect",
      rules: (required: boolean) => [
        checkRequired(array().of(number()).nullable(), true)(required),
        (v: number[]) =>
          v.length === 0 ||
          intersection(v, form.dislikedMusicIds).length === 0 ||
          "You cannot like and dislike a type of music",
      ],
      attrs: {
        options: formOptions.music,
        "help-text": "choose all that apply",
        display: "chip",
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    dislikedMusicIds: {
      comp: "BaseMultiSelect",
      rules: (required: boolean) => [
        checkRequired(array().of(number()).nullable(), true)(required),
        (v: number[]) =>
          v.length === 0 ||
          intersection(v, form.likedMusicIds).length === 0 ||
          "You cannot like and dislike a type of music",
      ],
      attrs: {
        options: formOptions.music,
        "help-text": "choose all that apply",
        display: "chip",
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    musicTime: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.musicTimes,
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    possessedQualityIds: {
      comp: "BaseMultiSelect",
      rules: checkRequired(array().of(number()).nullable(), true),
      attrs: {
        options: formOptions.qualities,
        "help-text": "choose all that apply",
        display: "chip",
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    desiredQualityIds: {
      comp: "BaseMultiSelect",
      rules: checkRequired(array().of(number()).nullable(), true),
      attrs: {
        options: formOptions.qualities,
        "help-text": "choose all that apply",
        display: "chip",
        "option-label": "label",
        "option-value": "value",
        "show-clear": true,
      },
    },
    // ----
    homeAddress1: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-class": "mb-3",
      },
    },
    homeAddress2: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-class": "mb-3",
      },
    },
    homeCity: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-class": "mb-3 col-md-6",
      },
    },
    homeState: {
      comp: "BaseDropdown",
      rules: checkRequired(string().nullable()),
      attrs: {
        options: formOptions.states,
        "option-label": "label",
        "option-value": "value",
        "div-class": "mb-3 col-md-3",
      },
    },
    homePostal: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-class": "mb-3 col-md-3",
      },
    },
    homeCountry: {
      comp: "BaseTextfield",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-class": "mb-3",
      },
    },
    // ---
    otherPrefs: {
      comp: "BaseTextArea",
      rules: checkRequired(string().nullable()),
      attrs: {
        "div-style": {},
      },
    },
    emergencyContacts: {
      comp: ContactInfoForm,
      rules: () =>
        computed(() =>
          contactRules(form.emergencyContacts as ContactInformation)
        ),
      attrs: {
        states: formOptions.states,
        "div-style": {},
      },
    },
  };

  const formFields = (fieldNames: string[]) =>
    fieldNames
      .map((fieldName) => ({
        name: fieldName,
        spec: formOptions.fieldSpecs[fieldName],
      }))
      .filter(({ spec }) => spec)
      .map(({ name, spec }) => ({
        name,
        spec,
        field: possibleFields[name as keyof typeof possibleFields],
      }))
      .map(
        ({ name, spec, field }) =>
          merge(field, {
            rules: field.rules(spec.formState === "Required"),
            attrs: {
              name,
              disabled: spec.formState === "Readonly",
              optional: spec.formState === "Optional",
              label: spec.label,
            },
          }) as GeneratedField
      );

  const studentInfoFields = formFields([
    "schoolid",
    "firstName",
    "middleName",
    "lastName",
    "gender",
    "dateOfBirth",
    "highschool",
    "classification",
    "email",
    "homephone",
    "cellphone",
    "allowSms",
    "summerphone",
    "isTransfer",
    "arrivalDt",
    "hasTransport",
  ]);
  const addressFields = formFields([
    "homeAddress1",
    "homeAddress2",
    "homeCity",
    "homeState",
    "homePostal",
    "homeCountry",
  ]);
  const aboutMeFields = formFields([
    "major",
    "classLoad",
    "study",
    "neatness",
    "bedtime",
    "waketime",
    "sleepLevel",
    "windows",
    "roomTemp",
    "lifestyle",
    "religious",
    "sabbathKeeping",
    "hobbyIds",
    "musicTime",
    "likedMusicIds",
    "dislikedMusicIds",
    "possessedQualityIds",
    "desiredQualityIds",
  ]);
  const roomPreferenceFields = formFields(["otherPrefs"]);

  const emergencyContactFields = formFields(["emergencyContacts"]);

  return {
    studentInfoFields,
    addressFields,
    aboutMeFields,
    roomPreferenceFields,
    emergencyContactFields,
  };
}
