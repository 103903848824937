<template>
  <div class="row">
    <base-textfield
      v-model="address1"
      div-class="mb-3"
      label="Address"
      :name="`${name}.address1`"
      :form-name="formName"
      :rules="rules.address1"
    />
    <base-textfield
      v-model="address2"
      div-class="mb-3"
      optional
      label="Address 2"
      :name="`${name}.address2`"
      :form-name="formName"
      :rules="rules.address2"
    />
    <base-textfield
      v-model="city"
      div-class="mb-3 col-md-6"
      label="City"
      :name="`${name}.city`"
      :form-name="formName"
      :rules="rules.city"
    />
    <base-dropdown
      v-if="country != null && !isInternational"
      v-model="state"
      div-class="mb-3 col-md-3"
      label="State/Province"
      :name="`${name}.state`"
      :options="statesForCountry"
      option-label="label"
      option-value="value"
      placeholder="Select State"
      :form-name="formName"
      :rules="rules.state as Validators<unknown>"
    />
    <base-textfield
      v-if="country != null && !isInternational"
      v-model="postal"
      div-class="mb-3 col-md-3"
      label="Postal/ZIP code"
      :name="`${name}.postal`"
      :form-name="formName"
      :rules="rules.postal"
    />
    <base-dropdown
      v-model="country"
      div-class="mb-3 col-md-6"
      label="Country"
      :name="`${name}.country`"
      :options="Object.values(countries)"
      option-label="label"
      option-value="value"
      placeholder="Select Country"
      :form-name="formName"
      :rules="rules.country as Validators<unknown>"
    />
  </div>
</template>

<script setup lang="ts">
import { useVModels } from "@vueuse/core";
import { computed, watch } from "vue";

import type { Validators } from "@/utilities/validations";

export type StateOptions = Record<
  string,
  {
    label: string;
    value: string;
    countryCode: string | null;
  }
>;

export type CountryOptions = Record<
  string,
  {
    label: string;
    value: string;
    isoCode: string;
  }
>;

export type AddressRules = {
  address1: Validators<string | null>;
  address2: Validators<string | null>;
  city: Validators<string | null>;
  state: Validators<string | null>;
  country: Validators<string | null>;
  postal: Validators<string | null>;
};

interface Props {
  name: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  postal: string | null;
  states: StateOptions;
  countries: CountryOptions;
  rules?: AddressRules;
  formName?: string;
}

const props = withDefaults(defineProps<Props>(), {
  rules: () => ({
    address1: [],
    address2: [],
    city: [],
    state: [],
    country: [],
    postal: [],
  }),
});

interface Emits {
  (e: "update:address1", payload: string): void;
  (e: "update:address2", payload: string): void;
  (e: "update:city", payload: string): void;
  (e: "update:state", payload: string | null): void;
  (e: "update:country", payload: string | null): void;
  (e: "update:postal", payload: string | null): void;
}

const emit = defineEmits<Emits>();

const { address1, address2, city, state, country, postal } = useVModels(
  props,
  emit
);

if (country.value != null && props.countries[country.value] == null) {
  country.value = null;
}

const isInternational = computed(() => {
  if (country.value === null) {
    return false;
  }

  const countryRecord = props.countries[country.value];

  return countryRecord?.isoCode !== "USA" && countryRecord?.isoCode !== "CAN";
});

const statesForCountry = computed(() => {
  if (isInternational.value || country.value == null) {
    return [];
  }

  return Object.values(props.states).filter(
    (s) => s.countryCode === country.value
  );
});

watch(isInternational, () => {
  state.value = null;
  postal.value = null;
});
</script>
