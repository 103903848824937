<template>
  <p>Room: {{ roomName }}</p>
  <p>Date: {{ today }}</p>
  <p>
    Mark each item as in "good" (green check) or "bad" (red x) condition. If you
    mark an item "bad", please leave a comment including problem details. If
    your room has a major maintenance issue, please contact your dean.
  </p>
  <table class="table table-striped">
    <thead>
      <tr>
        <th>Item</th>
        <th>Good/Bad</th>
        <th>Comments</th>
      </tr>
    </thead>
    <tbody>
      <template
        v-for="(item, i) in formDetails"
        :key="item.inspectionDefaultId"
      >
        <inspection-row
          :model-value="item"
          :name="`details[${i}]`"
          :form-name="formName"
          @update:model-value="formDetails[i] = $event"
        />
      </template>
    </tbody>
  </table>
  <p>
    I, {{ studentName }}, agree that this information is correct. Type your full
    name below to agree:
  </p>
  <base-textfield
    v-model="signature"
    name="signature"
    placeholder="Signature"
    :rules="rules.signature"
    div-style="max-width: 50ch"
    :form-name="formName"
  />
</template>
<script lang="ts">
export default { name: "InspectionStep" };
</script>

<script setup lang="ts">
import { DateTime } from "luxon";
import { toRefs } from "vue";
import { string } from "yup";

import { computedModels } from "@/utilities";

import InspectionRow from "./components/InspectionRow.vue";
import type { InspectionForm } from "./types";

interface Props {
  modelValue: InspectionForm;
  formName: string;
  studentName: string;
  roomName: string;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", payload: InspectionForm): void;
}

const emit = defineEmits<Emits>();

const { modelValue } = toRefs(props);

const { signature, details: formDetails } = computedModels(modelValue, emit);

const today = DateTime.now().toLocaleString(DateTime.DATE_SHORT);

const rules = {
  signature: string()
    .required()
    .equals([props.studentName], "Your signature does not match"),
};
</script>
