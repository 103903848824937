<template>
  <card-to-mobile-layout title="Other">
    <div class="row">
      <base-textfield
        v-model="cellphone"
        div-class="col-12 col-md-6 mb-3"
        label="Your Cell Phone"
        name="cellphone"
        :rules="rules.cellphone"
        :form-name="formName"
      />
      <base-textfield
        v-model="reason"
        div-class="col-md-6 col-12 mb-3"
        label="Reason"
        name="reason"
        :rules="rules.reason"
        :form-name="formName"
      />
      <base-textfield
        v-model="ccParent"
        optional
        div-class="col-md-6 col-12 mb-3"
        label="Email Parent"
        name="ccParent"
        :rules="rules.ccParent"
        :form-name="formName"
      />
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";

import * as HolidayLeave from "../types/holidayLeave";
import { Rules } from "../useValidations";

interface Props {
  modelValue: HolidayLeave.Other;
  rules: Rules;
  formName: string;
}

interface Emits {
  (e: "update:modelValue", value: HolidayLeave.Other): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = toRefs(props).modelValue;
const { cellphone, reason, ccParent } = computedModels(modelValue, emit);
</script>
