<template>
  <div v-if="single" key="single">
    <base-field
      :name="innerId"
      :class="[divClass, 'form-check', { 'is-invalid': showError }]"
    >
      <input
        :id="innerId"
        v-model="model"
        v-bind="$attrs"
        :class="{ 'form-check-input': true, 'is-invalid': showError }"
        type="checkbox"
        :name="name"
      />
      <label class="form-check-label" :for="name" v-html="label"> </label>
      <help-text
        :help-text="helpText"
        :error-message="errorMessage"
        :touched="touched"
      />
    </base-field>
  </div>
  <base-field
    v-else
    key="multi"
    :name="innerId"
    :class="[divClass, 'form-check', { 'is-invalid': showError }]"
  >
    <input
      :id="innerId"
      v-model="model"
      v-bind="$attrs"
      :class="{ 'form-check-input': true, 'is-invalid': showError }"
      type="checkbox"
      :name="name"
    />
    <label class="form-check-label" :for="name">
      <span v-html="label"></span>
    </label>
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, toRefs, watch } from "vue";

import { useField, Validators } from "@/utilities/validations";

type ModelValue = boolean;

interface Props {
  name: string;
  id?: string;
  modelValue: ModelValue;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<unknown>;
  formName?: string;
  helpText?: string;
  single?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  rules: () => [],
  single: false,
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const model = computed({
  get: () => props.modelValue,
  set: (val: ModelValue) => emit("update:modelValue", val),
});

if (model.value === undefined) {
  model.value = false;
}

const innerId = computed(() => props.id || props.name);

const { rules } = toRefs(props);

const { validate, touched, errorMessage, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(model, () => {
  validate();
});
</script>
